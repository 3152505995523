import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  useToast,
  Flex,
  Text,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Tooltip,
  Spinner,
  HStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { MdEdit, MdSave, MdClose, MdDelete, MdAdd } from 'react-icons/md';

const DelegateManagement = () => {
  const [delegates, setDelegates] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [editValue, setEditValue] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDelegates, setFilteredDelegates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newDelegateName, setNewDelegateName] = useState('');
  const toast = useToast();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();
  const [selectedDelegate, setSelectedDelegate] = useState(null);

  // Couleurs pour le mode sombre/clair
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const tableBgColor = useColorModeValue('white', 'gray.700'); 
  const tableHeaderBgColor = useColorModeValue('gray.50', 'gray.700');
  const tableRowHoverBg = useColorModeValue('gray.50', 'gray.600');
  const inputBgColor = useColorModeValue('white', 'gray.700');
  const modalBgColor = useColorModeValue('white', 'gray.800');

  // Ajout d'une couleur de texte pour l'input
  const inputTextColor = useColorModeValue('gray.800', 'white');

  const fetchDelegates = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        'https://app.falconmarketing.fr:3004/delegates/details'
      );
      const data = await response.json();
      if (data.delegates && Array.isArray(data.delegates)) {
        setDelegates(
          data.delegates.map((delegate) => ({
            id: delegate.delegateName,
            delegateName: delegate.delegateName,
            adherentCount: delegate.adherentCount || 0,
          }))
        );
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des délégués:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de charger les délégués',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDelegates();
  }, []);

  useEffect(() => {
    const filtered = delegates.filter((delegate) =>
      delegate.delegateName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDelegates(filtered);
  }, [searchTerm, delegates]);

  const handleEdit = (delegate) => {
    setEditingId(delegate.delegateName);
    setEditValue(delegate.delegateName);
  };

  const handleSave = async (delegate) => {
    try {
      const response = await fetch(
        `https://app.falconmarketing.fr:3004/delegates/${delegate.id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            oldName: delegate.delegateName,
            newName: editValue,
          }),
        }
      );

      if (!response.ok) throw new Error('Erreur lors de la mise à jour');

      const data = await response.json();

      setDelegates((prevDelegates) =>
        prevDelegates.map((d) =>
          d.delegateName === delegate.delegateName
            ? {
                ...d,
                delegateName: editValue,
                adherentCount: data.updatedAdherents,
              }
            : d
        )
      );

      toast({
        title: 'Succès',
        description: `Délégué mis à jour. ${data.updatedAdherents} adhérents affectés.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setEditingId(null);
    } catch (error) {
      console.error('Erreur:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de mettre à jour le délégué',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `https://app.falconmarketing.fr:3004/delegates/${selectedDelegate.delegateName}`,
        {
          method: 'DELETE',
        }
      );

      if (!response.ok) throw new Error('Erreur lors de la suppression');

      setDelegates((prev) =>
        prev.filter((d) => d.delegateName !== selectedDelegate.delegateName)
      );

      toast({
        title: 'Succès',
        description: 'Délégué supprimé avec succès',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      onDeleteClose();
    } catch (error) {
      console.error('Erreur:', error);
      toast({
        title: 'Erreur',
        description: 'Impossible de supprimer le délégué',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAddDelegate = async () => {
    try {
      const response = await fetch(
        'https://app.falconmarketing.fr:3004/delegates',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ delegateName: newDelegateName }),
        }
      );

      if (!response.ok) throw new Error("Erreur lors de l'ajout");

      const data = await response.json();

      setDelegates((prev) => [
        ...prev,
        {
          id: newDelegateName,
          delegateName: newDelegateName,
          adherentCount: 0,
        },
      ]);

      toast({
        title: 'Succès',
        description: 'Délégué ajouté avec succès',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setNewDelegateName('');
      onAddClose();
    } catch (error) {
      console.error('Erreur:', error);
      toast({
        title: 'Erreur',
        description: "Impossible d'ajouter le délégué",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Box 
        p={4} 
        bg={bgColor} 
        borderRadius="md" 
        boxShadow="md"
        borderColor={borderColor}
        color={textColor}
      >
        <Flex
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
          align={{ base: 'flex-start', md: 'center' }}
          mb={4}
        >
          <Text fontSize="2xl" fontWeight="bold" mb={{ base: 2, md: 0 }}>
            Gestion des Délégués
          </Text>
          <Button
            leftIcon={<MdAdd />}
            colorScheme="teal"
            onClick={onAddOpen}
            size={{ base: 'sm', md: 'md' }}
            px={4}
          >
            Ajouter un Délégué
          </Button>
        </Flex>
        <Flex mb={4} direction={{ base: 'column', md: 'row' }}>
          <Input
            placeholder="Rechercher un délégué..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            maxW="300px"
            mb={{ base: 2, md: 0 }}
            bg={inputBgColor}
            color={inputTextColor}
            borderColor={borderColor}
          />
        </Flex>
        {isLoading ? (
          <Flex justify="center" align="center" h="200px">
            <Spinner size="xl" color="teal.500" />
          </Flex>
        ) : (
          <Box overflowX="auto">
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th bg={tableHeaderBgColor} color={textColor}>Nom du Délégué</Th>
                  <Th bg={tableHeaderBgColor} color={textColor} isNumeric>Nombre d'Adhérents</Th>
                  <Th bg={tableHeaderBgColor} color={textColor}>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredDelegates.map((delegate) => (
                  <Tr 
                    key={delegate.delegateName}
                    _hover={{ bg: tableRowHoverBg }}
                    bg={tableBgColor}
                  >
                    <Td>
                      {editingId === delegate.delegateName ? (
                        <Input
                          value={editValue}
                          onChange={(e) => setEditValue(e.target.value)}
                          size="sm"
                          bg={inputBgColor}
                          borderColor={borderColor}
                        />
                      ) : (
                        delegate.delegateName
                      )}
                    </Td>
                    <Td isNumeric>{delegate.adherentCount}</Td>
                    <Td>
                      {editingId === delegate.delegateName ? (
                        <HStack spacing={2}>
                          <IconButton
                            icon={<MdSave />}
                            colorScheme="green"
                            onClick={() => handleSave(delegate)}
                            aria-label="Sauvegarder"
                            size="sm"
                          />
                          <IconButton
                            icon={<MdClose />}
                            colorScheme="red"
                            onClick={() => setEditingId(null)}
                            aria-label="Annuler"
                            size="sm"
                          />
                        </HStack>
                      ) : (
                        <HStack spacing={2}>
                          <Tooltip label="Modifier">
                            <IconButton
                              icon={<MdEdit />}
                              colorScheme="blue"
                              onClick={() => handleEdit(delegate)}
                              aria-label="Modifier"
                              size="sm"
                            />
                          </Tooltip>
                          <Tooltip label="Supprimer">
                            <IconButton
                              icon={<MdDelete />}
                              colorScheme="red"
                              onClick={() => {
                                setSelectedDelegate(delegate);
                                onDeleteOpen();
                              }}
                              aria-label="Supprimer"
                              size="sm"
                            />
                          </Tooltip>
                        </HStack>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}

        {/* Modal pour la suppression */}
        <Modal isOpen={isDeleteOpen} onClose={onDeleteClose} isCentered>
          <ModalOverlay />
          <ModalContent bg={modalBgColor}>
            <ModalHeader color={textColor}>Supprimer le délégué</ModalHeader>
            <ModalBody color={textColor}>
              Êtes-vous sûr de vouloir supprimer le délégué{' '}
              <strong>{selectedDelegate?.delegateName}</strong> ?
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" onClick={onDeleteClose}>
                Annuler
              </Button>
              <Button colorScheme="red" ml={3} onClick={handleDelete}>
                Supprimer
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Modal pour ajouter un délégué */}
        <Modal isOpen={isAddOpen} onClose={onAddClose} isCentered>
          <ModalOverlay />
          <ModalContent bg={modalBgColor}>
            <ModalHeader color={textColor}>Ajouter un Délégué</ModalHeader>
            <ModalBody>
              <Input
                placeholder="Nom du nouveau délégué"
                value={newDelegateName}
                onChange={(e) => setNewDelegateName(e.target.value)}
                bg={inputBgColor}
                borderColor={borderColor}
              />
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" onClick={onAddClose}>
                Annuler
              </Button>
              <Button
                colorScheme="teal"
                ml={3}
                onClick={handleAddDelegate}
                isDisabled={!newDelegateName.trim()}
              >
                Ajouter
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
};

export default DelegateManagement;

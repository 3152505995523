import React from 'react';

import { Icon } from '@chakra-ui/react';
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdDiversity3,
  MdMap,
  MdArticle,
  MdSupervisorAccount,
  MdGroup,
} from 'react-icons/md';



// Admin Imports
import MainDashboard from 'views/admin/default';
import Statistiques from 'views/admin/statistiques';

// Auth Imports
import SignInCentered from 'views/auth/signIn';
import SignUpCentered from 'views/auth/signUp';
import ListDocuments from 'views/admin/default/components/listdocuments';
import SalesMap from 'views/admin/default/components/salesmap';
import CommercialMap from 'views/admin/default/components/map';
import EditPDFComponent from 'views/admin/paperwork/components/formPDF';
import FicheDeces from 'views/admin/default/components/FicheDeces';
import DecesList from 'views/admin/default/components/DecesList';
import DocumentsPage from 'views/admin/documents';
import DelegateManagement from 'views/admin/default/components/DelegateManagement';

const routes = [
  {
    name: 'Accueil',
    layout: '/admin',
    path: '/default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: 'Comptabilité',
    layout: '/admin',
    path: '/compta',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: Statistiques,
  },
  {
    name: 'Fiche Décès',
    layout: '/admin',
    path: '/deces',
    exact: true, // Ajoutez cette ligne
    icon: <Icon as={MdArticle} width="20px" height="20px" color="inherit" />,
    component: FicheDeces,
  },
  {
    name: 'Liste des Décès',
    layout: '/admin',
    path: '/listedeces',
    exact: true, // Cette ligne est déjà présente
    icon: <Icon as={MdArticle} width="20px" height="20px" color="inherit" />,
    component: DecesList,
  },
  {
    name: 'Sign In',
    layout: '/auth',
    path: '/sign-in',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
    hideLink: true,
  },
  {
    name: 'Sign Up',
    layout: '/auth',
    path: '/sign-up',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignUpCentered,
    hideLink: true,
  },
  {
    name: 'Documents',
    layout: '/admin',
    path: '/documents',
    icon: <Icon as={MdArticle} width="20px" height="20px" color="inherit" />,
    component: DocumentsPage,
  },
  {
    name: 'Gestion des Délégués',
    layout: '/admin',
    path: '/delegates',
    icon: <Icon as={MdSupervisorAccount} width='20px' height='20px' color='inherit' />,
    component: DelegateManagement,
    secondary: true,
  }

];

export default routes;

import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  VStack,
  HStack,
  Flex,
  Divider,
  Icon,
  Box,
  Heading,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import { Document, Page, Text as PdfText, View, StyleSheet, PDFViewer, pdf } from '@react-pdf/renderer';
import AdherentPDF from './AdherentPDF';
import DecesModal from './DecesModal';
import { MdDelete } from 'react-icons/md';

const formatDateToFrench = (dateString) => {
  // Si pas de date ou date invalide, retourner une chaîne vide
  if (!dateString || dateString === 'null' || dateString === 'undefined') {
    return '';
  }
  
  const date = new Date(dateString);
  
  // Vérifier si la date est valide et différente de 1970
  if (isNaN(date.getTime()) || 
      date.getFullYear() === 1970 || 
      dateString === '1970-01-01' ||
      dateString === '1970-01-01T00:00:00.000Z') {
    return '';
  }
  
  return date.toLocaleDateString('fr-FR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });
};

const AdherentDetailsModal = ({ isOpen, onClose, adherent }) => {
  const [children, setChildren] = useState([]);
  const [files, setFiles] = useState([]);
  const [isDecesModalOpen, setIsDecesModalOpen] = useState(false);
  const toast = useToast();
  


  const generatePDF = async (adherent, children, files) => {
    const blob = await pdf(
      <AdherentPDF adherent={adherent} children={children} files={files} />
    ).toBlob();
    return blob;
  };

  const handleDownload = async () => {
    const pdfBlob = await generatePDF(adherent, children, files);
    const pdfUrl = URL.createObjectURL(pdfBlob);
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = `adherent_${adherent.id}.pdf`;
    link.click();
  };

  useEffect(() => {
    const fetchData = async () => {
      if (adherent && adherent.id) {
        try {
          const childrenResponse = await fetch(
            `https://app.falconmarketing.fr:3004/adherents/${adherent.id}/children`
          );
          const childrenData = await childrenResponse.json();
          setChildren(childrenData.children);
          const filesResponse = await fetch(
            `https://app.falconmarketing.fr:3004/adherents/${adherent.id}/files`
          );
          const filesData = await filesResponse.json();
          setFiles(filesData.files);
        } catch (error) {
          console.error("Failed to fetch data:", error);
          setChildren([]);
          setFiles([]);
        }
      }
    };
    if (isOpen) {
      fetchData();
    }
  }, [isOpen, adherent]);

  const handleDeleteFile = async (fileId) => {
    try {
      const response = await fetch(`https://app.falconmarketing.fr:3004/adherents/${adherent.id}/files/${fileId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error("Erreur lors de la suppression du fichier");
      }

      // Rafraîchir la liste des fichiers après suppression
      const updatedFiles = files.filter(file => file.id !== fileId);
      setFiles(updatedFiles);

      toast({
        title: 'Succès',
        description: 'Le fichier a été supprimé avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erreur',
        description: `Impossible de supprimer le fichier: ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const fetchFiles = async () => {
      if (adherent && adherent.id) {
        try {
          const response = await fetch(`https://app.falconmarketing.fr:3004/adherents/${adherent.id}/files`);
          if (!response.ok) {
            throw new Error('Erreur lors du chargement des fichiers');
          }
          const data = await response.json();
          setFiles(data.files);
        } catch (error) {
          console.error('Erreur:', error);
          toast({
            title: 'Erreur',
            description: 'Impossible de charger les fichiers',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };

    if (isOpen) {
      fetchFiles();
    }
  }, [isOpen, adherent]);

  const handleFileDownload = (filePath) => {
    // Construire l'URL complète pour le téléchargement
    const fileUrl = `https://app.falconmarketing.fr:3004/${filePath}`;
    window.open(fileUrl, '_blank');
  };

  if (!adherent) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading size="md">Détails de l'adhérent</Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={6} align="start">
            <Box>
              <Text fontWeight="bold">Nom et Prénom (M.):</Text>
              <Text>{adherent.mrName}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Nom et Prénom (Mme):</Text>
              <Text>{adherent.mrsName}</Text>
            </Box>

            <HStack spacing={6} width="100%">
              <Box flex="1">
                <Text fontWeight="bold">Date de naissance:</Text>
                <Text>{formatDateToFrench(adherent.birthDate)}</Text>
              </Box>
              <Box flex="1">
                <Text fontWeight="bold">Date de naissance (conjoint):</Text>
                <Text>{formatDateToFrench(adherent.birthDateLine2)}</Text>
              </Box>
            </HStack>

            <HStack spacing={6} width="100%">
              <Box flex="1">
                <Text fontWeight="bold">N° de sécurité sociale:</Text>
                <Text>{adherent.socialSecurityNumber}</Text>
              </Box>
              <Box flex="1">
                <Text fontWeight="bold">N° de sécurité sociale (conjoint):</Text>
                <Text>{adherent.socialSecurityNumberLine2}</Text>
              </Box>
            </HStack>

            <Box>
              <Text fontWeight="bold">Adresse:</Text>
              <Text>{adherent.address}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Téléphone:</Text>
              <Text>{adherent.phone}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Email:</Text>
              <Text>{adherent.email}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Nationalité:</Text>
              <Text>{adherent.nationality}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Lieu d'inhumation:</Text>
              <Text>{adherent.burialSite}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Situation familiale:</Text>
              <Text>{getFamilyStatusLabel(adherent.maritalStatus)}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Statut de cotisation:</Text>
              <Text>{adherent.contributionStatus}</Text>
            </Box>
            <Box>
              <Text fontWeight="bold">Nom du délégué:</Text>
              <Text>{adherent.delegateName}</Text>
            </Box>
          </VStack>

          {children.length > 0 && (
            <Box mt={6}>
              <Heading size="sm" mb={2}>
                Enfants
              </Heading>
              <Divider mb={4} />
              <VStack spacing={2} align="start">
                {children.map((child) => (
                  <Text key={child.id}>
                    {child.name} - {formatDateToFrench(child.birthDate)}
                  </Text>
                ))}
              </VStack>
            </Box>
          )}

          {files.length > 0 && (
            <Box mt={6}>
              <Heading size="sm" mb={2}>
                Pièces justificatives
              </Heading>
              <Divider mb={4} />
              <VStack spacing={2} align="start">
                {files.map((file) => {
                  const fileName = file.filePath.split('/').pop();
                  return (
                    <HStack
                      key={file.id}
                      spacing={2}
                      width="100%"
                      justify="space-between"
                    >
                      <HStack>
                        <Icon as={DownloadIcon} boxSize={5} />
                        <Text>{fileName}</Text>
                      </HStack>
                      <HStack>
                        <IconButton
                          aria-label="Télécharger le fichier"
                          icon={<DownloadIcon />}
                          colorScheme="blue"
                          size="sm"
                          onClick={() => handleFileDownload(file.filePath)}
                        />
                        <IconButton
                          aria-label="Supprimer le fichier"
                          icon={<MdDelete />}
                          colorScheme="red"
                          size="sm"
                          onClick={() => handleDeleteFile(file.id)}
                        />
                      </HStack>
                    </HStack>
                  );
                })}
              </VStack>
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleDownload}>
            Télécharger PDF
          </Button>
          <Button onClick={onClose}>Fermer</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

// Fonction utilitaire pour convertir le statut familial en libellé
const getFamilyStatusLabel = (status) => {
  const statusMap = {
    'married': 'Marié',
    'single': 'Célibataire',
    'divorcedWithChildren': 'Divorcé avec enfants',
    'divorcedWithoutChildren': 'Divorcé sans enfants',
    'widowed': 'Veuve/Veuf'
  };
  return statusMap[status] || status;
};

export default AdherentDetailsModal;
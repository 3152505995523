import React from 'react';
import {
  Box,
  List,
  ListItem,
  Link,
  Icon,
  Text,
  useToast
} from '@chakra-ui/react';
import { FiDownload, FiFile } from 'react-icons/fi';


const DecesFilesList = ({ files }) => {
  const toast = useToast();

  const handleDownload = async (fileId, filename) => {
    console.log('Début du téléchargement:', { fileId, filename });
    try {
      const downloadUrl = `https://app.falconmarketing.fr:3004/deces/files/${fileId}`;
      console.log('URL de téléchargement:', downloadUrl);
      
      const response = await fetch(downloadUrl);
      console.log('Statut de la réponse:', response.status);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const blob = await response.blob();
      console.log('Blob reçu:', blob.size, 'bytes');
      
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      
      console.log('Téléchargement initié');
      
      // Nettoyage
      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Erreur détaillée lors du téléchargement:', {
        message: error.message,
        stack: error.stack,
        error
      });
      toast({
        title: "Erreur de téléchargement",
        description: "Impossible de télécharger le fichier. Veuillez réessayer.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Text fontSize="lg" fontWeight="bold" mb={2}>
        Pièces justificatives
      </Text>
      <List spacing={2}>
        {files.map((file) => (
          <ListItem 
            key={file.id}
            display="flex"
            alignItems="center"
            p={2}
            bg="gray.50"
            borderRadius="md"
          >
            <Icon as={FiFile} mr={2} />
            <Text flex="1">{file.filename}</Text>
            <Link
              onClick={() => handleDownload(file.id, file.filename)}
              cursor="pointer"
              color="blue.500"
              _hover={{ color: 'blue.600' }}
            >
              <Icon as={FiDownload} />
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default DecesFilesList; 
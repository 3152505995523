import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ContextProvider } from 'contexts/AuthContext';
import 'firebase/functions';
import { DataProvider } from 'contexts/DataProvider';
import "antd/dist/reset.css";




const history = createBrowserHistory();


ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <ContextProvider>
        <Router history={history}>
          <DataProvider>
            <Switch>
              <Route path={`/auth`} component={AuthLayout} />
              <Route path={`/admin`} component={AdminLayout} />
              <Redirect from="/" to="/admin" />
            </Switch>
          </DataProvider>
        </Router>
      </ContextProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById('root') 
);
import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  TableContainer,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Select,
  VStack,
  HStack,
  Text,
  useToast,
  Divider,
  ModalFooter,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  IconButton,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import { FiTrash2, FiEye, FiDownload, FiFilter, FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Document, Page, Text as PDFText, View, StyleSheet, PDFDownloadLink, Font } from '@react-pdf/renderer';
import DecesFilesList from './DecesFilesList';

// Enregistrer les polices personnalisées
Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
      fontWeight: 300,
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
      fontWeight: 400,
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
      fontWeight: 500,
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 700,
    },
  ],
});

const DecesList = () => {
  const [decesData, setDecesData] = useState({
    deces: [],
    isLoading: true,
    error: null,
  });
  const [selectedDeces, setSelectedDeces] = useState(null);
  const [selectedDecesFiles, setSelectedDecesFiles] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sortOrder, setSortOrder] = useState('asc');
  const [filterYear, setFilterYear] = useState('');
  const toast = useToast();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [decesToDelete, setDecesToDelete] = useState(null);
  const cancelRef = useRef();

  // Ajout des valeurs de couleur pour le mode sombre/clair
  const boxBg = useColorModeValue('white', 'gray.800');
  const headingColor = useColorModeValue('gray.800', 'white');
  const tableBg = useColorModeValue('white', 'gray.700');
  const theadBg = useColorModeValue('gray.100', 'gray.600');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const secondaryTextColor = useColorModeValue('gray.600', 'gray.300');
  const modalBg = useColorModeValue('white', 'gray.800');

  // Déplacer useColorModeValue en dehors des fonctions de rendu
  const hoverBg = useColorModeValue('gray.50', 'gray.700');

  useEffect(() => {
    fetchDeces();
  }, []);

  const fetchDeces = async () => {
    try {
      setDecesData({ ...decesData, isLoading: true });
      const response = await axios.get('https://app.falconmarketing.fr:3004/deces');
      if (response.data && Array.isArray(response.data.deces)) {
        setDecesData({ deces: response.data.deces, isLoading: false, error: null });
      } else {
        throw new Error("La réponse du serveur n'est pas valide.");
      }
    } catch (error) {
      setDecesData({ deces: [], isLoading: false, error });
      toast({
        title: 'Erreur',
        description: 'Impossible de récupérer les décès.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(decesData.deces);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Deces');
    XLSX.writeFile(workbook, 'deces.xlsx');
  };

  const handleViewDetails = async (deces) => {
    try {
      const response = await axios.get(`https://app.falconmarketing.fr:3004/deces/${deces.id}/files`);
      setSelectedDecesFiles(response.data.files);
      setSelectedDeces(deces);
      onOpen();
    } catch (error) {
      toast({
        title: 'Erreur',
        description: 'Impossible de récupérer les fichiers.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSortChange = () => {
    setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
  };

  const handleFilterChange = (event) => {
    setFilterYear(event.target.value);
  };

  const uniqueYears = useMemo(() => {
    return [...new Set(decesData.deces.map((d) => new Date(d.dateDecesLieu).getFullYear()))].sort(
      (a, b) => b - a
    );
  }, [decesData.deces]);

  const sortedAndFilteredDeces = useMemo(() => {
    let sortedDeces = [...decesData.deces].sort((a, b) => {
      const dateA = new Date(a.dateDecesLieu);
      const dateB = new Date(b.dateDecesLieu);
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });
    if (filterYear) {
      sortedDeces = sortedDeces.filter(
        (d) => new Date(d.dateDecesLieu).getFullYear().toString() === filterYear
      );
    }
    return sortedDeces;
  }, [decesData.deces, sortOrder, filterYear]);

  const handleDelete = (deces) => {
    setDecesToDelete(deces);
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await axios.delete(`https://app.falconmarketing.fr:3004/deces/${decesToDelete.id}`);
      if (response.status === 200) {
        toast({
          title: 'Succès',
          description: 'Le décès a été supprimé avec succès.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        fetchDeces();
      }
    } catch (error) {
      toast({
        title: 'Erreur',
        description: 'Impossible de supprimer le décès.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeleteDialogOpen(false);
      setDecesToDelete(null);
    }
  };

  // Styles pour le PDF
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#FFFFFF',
      padding: 40,
      fontFamily: 'Roboto',
    },
    header: {
      marginBottom: 30,
      borderBottomWidth: 2,
      borderBottomColor: '#1a365d',
      paddingBottom: 10,
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      color: '#1a365d',
      fontWeight: 700,
      marginBottom: 10,
    },
    subtitle: {
      fontSize: 12,
      textAlign: 'center',
      color: '#4a5568',
      marginBottom: 20,
    },
    section: {
      marginBottom: 20,
    },
    sectionTitle: {
      fontSize: 16,
      fontWeight: 500,
      color: '#2c5282',
      marginBottom: 10,
      backgroundColor: '#EDF2F7',
      padding: 8,
      borderRadius: 4,
    },
    row: {
      flexDirection: 'row',
      marginBottom: 8,
      paddingBottom: 4,
      borderBottomWidth: 1,
      borderBottomColor: '#E2E8F0',
    },
    label: {
      width: '40%',
      fontSize: 12,
      color: '#4a5568',
      fontWeight: 500,
    },
    value: {
      width: '60%',
      fontSize: 12,
      color: '#1a202c',
    },
    expensesGrid: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginBottom: 20,
    },
    expenseItem: {
      width: '50%',
      flexDirection: 'row',
      marginBottom: 8,
      paddingRight: 10,
    },
    totalSection: {
      marginTop: 20,
      borderTopWidth: 2,
      borderTopColor: '#E2E8F0',
      paddingTop: 10,
    },
    totalRow: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: 5,
    },
    totalLabel: {
      fontSize: 14,
      fontWeight: 500,
      color: '#2c5282',
      marginRight: 20,
    },
    totalValue: {
      fontSize: 14,
      fontWeight: 700,
      color: '#1a365d',
    },
    observations: {
      marginTop: 20,
      padding: 10,
      backgroundColor: '#F7FAFC',
      borderRadius: 4,
    },
    footer: {
      position: 'absolute',
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: '#718096',
      fontSize: 10,
      paddingTop: 10,
      borderTopWidth: 1,
      borderTopColor: '#E2E8F0',
    },
  });

  const MyDocument = ({ deces }) => (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* En-tête */}
        <View style={styles.header}>
          <PDFText style={styles.title}>Fiche de décès</PDFText>
          <PDFText style={styles.subtitle}>Association El Mektoub</PDFText>
        </View>

        {/* Informations générales */}
        <View style={styles.section}>
          <PDFText style={styles.sectionTitle}>Informations générales</PDFText>
          <View style={styles.row}>
            <PDFText style={styles.label}>Délégué</PDFText>
            <PDFText style={styles.value}>{deces.delegue}</PDFText>
          </View>
          <View style={styles.row}>
            <PDFText style={styles.label}>Nom du défunt</PDFText>
            <PDFText style={styles.value}>{deces.nomDefunt}</PDFText>
          </View>
          <View style={styles.row}>
            <PDFText style={styles.label}>Enfant</PDFText>
            <PDFText style={styles.value}>{deces.enfant ? 'Oui' : 'Non'}</PDFText>
          </View>
          <View style={styles.row}>
            <PDFText style={styles.label}>Date de décès</PDFText>
            <PDFText style={styles.value}>
              {new Date(deces.dateDecesLieu).toLocaleDateString('fr-FR')}
            </PDFText>
          </View>
        </View>

        {/* Lieux */}
        <View style={styles.section}>
          <PDFText style={styles.sectionTitle}>Lieux</PDFText>
          <View style={styles.row}>
            <PDFText style={styles.label}>Lieu de décès</PDFText>
            <PDFText style={styles.value}>{deces.lieuDeces}</PDFText>
          </View>
          {deces.lieuDeces2 && (
            <View style={styles.row}>
              <PDFText style={styles.label}>Lieu de décès (autre)</PDFText>
              <PDFText style={styles.value}>{deces.lieuDeces2}</PDFText>
            </View>
          )}
          <View style={styles.row}>
            <PDFText style={styles.label}>Lieu d'enterrement</PDFText>
            <PDFText style={styles.value}>{deces.lieuEnterrement}</PDFText>
          </View>
        </View>

        {/* Dépenses */}
        <View style={styles.section}>
          <PDFText style={styles.sectionTitle}>Dépenses</PDFText>
          <View style={styles.expensesGrid}>
            {[
              ['Funérarium', deces.funerarium],
              ['Toilette', deces.toilette],
              ['Pompes funèbres', deces.pompesFunebres],
              ["Billet d'accompagnement", deces.billetAccompagnement],
              ['Traitement', deces.traitement],
              ['Repas', deces.repas],
              ['Concession', deces.concession],
              ['Décès en Algérie', deces.decesAlgerie],
            ].map(([label, value]) => (
              <View style={styles.expenseItem} key={label}>
                <PDFText style={styles.label}>{label}</PDFText>
                <PDFText style={styles.value}>{value} €</PDFText>
              </View>
            ))}
          </View>
        </View>

        {/* Total */}
        <View style={styles.totalSection}>
          <View style={styles.totalRow}>
            <PDFText style={styles.totalLabel}>Total</PDFText>
            <PDFText style={styles.totalValue}>{deces.total} €</PDFText>
          </View>
          <View style={styles.totalRow}>
            <PDFText style={styles.totalLabel}>Montant restant</PDFText>
            <PDFText style={styles.totalValue}>{deces.montantRestant} €</PDFText>
          </View>
        </View>

        {/* Observations */}
        {deces.observations && (
          <View style={styles.observations}>
            <PDFText style={styles.sectionTitle}>Observations</PDFText>
            <PDFText style={styles.value}>{deces.observations}</PDFText>
          </View>
        )}

        {/* Pied de page */}
        <View style={styles.footer}>
          <PDFText>
            Association El Mektoub - Document généré le {new Date().toLocaleDateString('fr-FR')}
          </PDFText>
        </View>
      </Page>
    </Document>
  );

  return (
    <Box 
      mt="100px" 
      bg={boxBg} 
      borderRadius="md" 
      boxShadow="md" 
      p={6}
      borderColor={borderColor}
    >
      <Heading 
        as="h2" 
        size="xl" 
        mb={6} 
        textAlign="center"
        color={headingColor}
      >
        Liste des décès
      </Heading>
      <Flex justify="space-between" mb={4} wrap="wrap">
        <HStack spacing={2} mb={{ base: 4, md: 0 }}>
          <FiFilter color={textColor} />
          <Select
            placeholder="Filtrer par année"
            value={filterYear}
            onChange={handleFilterChange}
            maxW="200px"
            bg={boxBg}
            color={textColor}
            borderColor={borderColor}
          >
            {uniqueYears.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Select>
        </HStack>
        <HStack spacing={2}>
          <Button
            onClick={handleSortChange}
            colorScheme="blue"
            leftIcon={sortOrder === 'asc' ? <FiChevronUp /> : <FiChevronDown />}
          >
            Trier par date
          </Button>
          <Button onClick={exportToExcel} colorScheme="blue" leftIcon={<FiDownload />}>
            Exporter en Excel
          </Button>
        </HStack>
      </Flex>
      {decesData.isLoading ? (
        <Flex justify="center" align="center" minH="200px">
          <Spinner color={textColor} />
        </Flex>
      ) : (
        <TableContainer>
          <Table variant="simple">
            <Thead bg={theadBg}>
              <Tr>
                <Th color={secondaryTextColor}>Délégué</Th>
                <Th color={secondaryTextColor}>Nom du défunt</Th>
                <Th color={secondaryTextColor}>Date du décès</Th>
                <Th color={secondaryTextColor}>Lieu d'enterrement</Th>
                <Th isNumeric color={secondaryTextColor}>Total</Th>
                <Th color={secondaryTextColor}>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {sortedAndFilteredDeces.map((deces) => (
                <Tr 
                  key={deces.id}
                  _hover={{ bg: hoverBg }}
                >
                  <Td color={textColor}>{deces.delegue}</Td>
                  <Td color={textColor}>{deces.nomDefunt}</Td>
                  <Td color={textColor}>
                    {new Date(deces.dateDecesLieu).toLocaleDateString('fr-FR')}
                  </Td>
                  <Td color={textColor}>{deces.lieuEnterrement}</Td>
                  <Td isNumeric color={textColor}>{deces.total} €</Td>
                  <Td>
                    <HStack spacing={2}>
                      <IconButton
                        icon={<FiEye />}
                        colorScheme="blue"
                        onClick={() => handleViewDetails(deces)}
                        aria-label="Voir les détails"
                      />
                      <PDFDownloadLink
                        document={<MyDocument deces={deces} />}
                        fileName={`deces_${deces.nomDefunt}.pdf`}
                      >
                        {({ loading }) => (
                          <IconButton
                            icon={<FiDownload />}
                            colorScheme="green"
                            isLoading={loading}
                            aria-label="Télécharger PDF"
                          />
                        )}
                      </PDFDownloadLink>
                      <IconButton
                        icon={<FiTrash2 />}
                        colorScheme="red"
                        onClick={() => handleDelete(deces)}
                        aria-label="Supprimer"
                      />
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      {/* Modal pour les détails */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent bg={modalBg}>
          <ModalHeader color={headingColor}>Détails du décès</ModalHeader>
          <ModalCloseButton color={textColor} />
          <ModalBody>
            {selectedDeces && (
              <>
                <VStack align="stretch" spacing={4}>
                  <Box>
                    <Text fontWeight="bold" color={textColor}>Délégué :</Text>
                    <Text color={secondaryTextColor}>{selectedDeces.delegue}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" color={textColor}>Nom du défunt :</Text>
                    <Text color={secondaryTextColor}>{selectedDeces.nomDefunt}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" color={textColor}>Date du décès :</Text>
                    <Text color={secondaryTextColor}>
                      {new Date(selectedDeces.dateDecesLieu).toLocaleDateString('fr-FR')}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" color={textColor}>Lieu du décès :</Text>
                    <Text color={secondaryTextColor}>{selectedDeces.lieuDeces}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" color={textColor}>Lieu d'enterrement :</Text>
                    <Text color={secondaryTextColor}>{selectedDeces.lieuEnterrement}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" color={textColor}>Date d'inhumation :</Text>
                    <Text color={secondaryTextColor}>
                      {selectedDeces.dateInhumation
                        ? new Date(selectedDeces.dateInhumation).toLocaleDateString('fr-FR')
                        : 'Non spécifiée'}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" color={textColor}>Observations :</Text>
                    <Text color={secondaryTextColor}>{selectedDeces.observations || 'Aucune observation'}</Text>
                  </Box>
                </VStack>

                <Divider my={4} borderColor={borderColor} />

                <DecesFilesList files={selectedDecesFiles} />
              </>
            )}
          </ModalBody>
          <ModalFooter borderTopColor={borderColor}>
            <Button onClick={onClose}>Fermer</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* AlertDialog pour la suppression */}
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg={modalBg}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold" color={headingColor}>
              Confirmer la suppression
            </AlertDialogHeader>
            <AlertDialogBody color={textColor}>
              Êtes-vous sûr de vouloir supprimer ce décès ? Cette action est irréversible.
              {decesToDelete && (
                <Text mt={2} color={secondaryTextColor}>
                  Décès de : {decesToDelete.nomDefunt}
                </Text>
              )}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleteDialogOpen(false)}>
                Annuler
              </Button>
              <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default DecesList;

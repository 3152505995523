import React, { createContext, useContext, useState, useEffect } from "react";

const DataContext = createContext();

export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const [adherents, setAdherents] = useState([]);
  const [delegates, setDelegates] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Récupérer les adhérents
        const adherentsResponse = await fetch("https://app.falconmarketing.fr:3004/adherents");
        const adherentsData = await adherentsResponse.json();
        setAdherents(adherentsData.adherents);

        // Récupérer les délégués
        const delegatesResponse = await fetch("https://app.falconmarketing.fr:3004/delegates");
        const delegatesData = await delegatesResponse.json();
        setDelegates(delegatesData.delegateNames);
      } catch (error) {
        console.error("Erreur lors du chargement des données :", error);
      }
    };

    fetchData();
  }, []);

  return (
    <DataContext.Provider value={{ adherents, delegates }}>
      {children}
    </DataContext.Provider>
  );
};
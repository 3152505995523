import React, { useState, useEffect } from 'react';
import { Document, Page, View, Text, StyleSheet, Font, pdf } from '@react-pdf/renderer';
import {
  Box,
  Flex,
  Heading,
  Input,
  Checkbox,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Textarea,
  Grid,
  GridItem,
  Stack,
  VStack,
  SimpleGrid,
  InputGroup,
  InputRightElement,
  Select,
  useColorModeValue
} from '@chakra-ui/react';
import { format } from 'date-fns';
import axios from 'axios';
import { useLocation } from 'react-router-dom';



// Importer la police de caractères
Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const styles = StyleSheet.create({
  page: {
    padding: 20, // Réduit de 30 à 20 pour économiser de l'espace
    backgroundColor: '#E7E9EB'
  },
  heading: {
    fontSize: 18, // Réduit pour occuper moins d'espace
    fontFamily: 'Oswald',
    color: '#333',
    textAlign: 'center',
    marginBottom: 15 // Réduit pour économiser de l'espace
  },
  section: {
    marginBottom: 15, // Réduit pour une meilleure utilisation de l'espace
    padding: 10, // Légère réduction pour économiser de l'espace
    backgroundColor: '#FFF',
    borderRadius: 5
  },
  sectionHeading: {
    fontSize: 14, // Taille réduite pour une meilleure économie de l'espace
    fontFamily: 'Oswald',
    color: '#555',
    marginBottom: 8 // Réduit pour économiser de l'espace
  },
  label: {
    fontSize: 12, // Taille réduite pour une cohérence et économie d'espace
    fontFamily: 'Oswald',
    color: '#777'
  },
  value: {
    fontSize: 12, // Assure une uniformité avec les labels
    fontFamily: 'Oswald',
    color: '#333',
    marginBottom: 4 // Réduit légèrement
  },
  footer: {
    fontSize: 10, // Réduit pour occuper moins d'espace
    fontFamily: 'Oswald',
    color: '#999',
    textAlign: 'center',
    marginTop: 20 // Réduit pour une meilleure économie d'espace
  }
});




const FicheDeces = () => {
  const [formData, setFormData] = useState({
    delegue: '',
    nomDefunt: '',
    enfant: false,
    dateDecesLieu: format(new Date(), 'yyyy-MM-dd'), // Initialiser avec la date d'aujourd'hui
    lieuDeces: '',
    lieuDeces2: '',
    lieuEnterrement: '',
    funerarium: '',
    toilette: '',
    pompesFunebres: '',
    billetAccompagnement: '',
    traitement: '',
    repas: '',
    concession: '',
    decesAlgerie: '',
    total: 0,
    montantRestant: '',
    observations: ''
  });


  const numericFields = [
    'funerarium',
    'toilette',
    'pompesFunebres',
    'billetAccompagnement',
    'traitement',
    'repas',
    'concession',
    'decesAlgerie',
    'total',
    'montantRestant'
  ];

  const [errors, setErrors] = useState({});
  const location = useLocation();
  const { mrName, mrsName, delegateName } = location.state || {};
  

  const validateForm = () => {
    let newErrors = {};
    if (!formData.delegue) newErrors.delegue = "Ce champ est obligatoire.";
    if (!formData.nomDefunt.trim()) newErrors.nomDefunt = "Ce champ est obligatoire.";
    if (!formData.dateDecesLieu) newErrors.dateDecesLieu = "Ce champ est obligatoire.";
    if (!formData.lieuDeces2.trim()) newErrors.lieuDeces2 = "Ce champ est obligatoire.";
    if (!formData.lieuEnterrement) newErrors.lieuEnterrement = "Ce champ est obligatoire.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [delegates, setDelegates] = useState([]);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      // Gestion spéciale pour les checkboxes de lieu et d'enterrement
      if (name === 'lieuDeces' || name === 'lieuEnterrement') {
        setFormData({
          ...formData,
          [name]: value // Pour ces checkboxes, on utilise la valeur plutôt que checked
        });
      } else {
        // Pour les autres checkboxes (comme 'enfant')
        setFormData({
          ...formData,
          [name]: checked
        });
      }
    } else {
      // Gestion des autres types de champs
      const numericValue = numericFields.includes(name) ? parseFloat(value) || 0 : value;
      const dateValue = name === 'dateDecesLieu' ? (value !== '' ? new Date(value) : null) : numericValue;

      setFormData({
        ...formData,
        [name]: dateValue
      });
    }

    // Effacer les erreurs pour le champ actuel
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      alert("Veuillez remplir tous les champs obligatoires.");
      return;
    }

    if (selectedFiles.length === 0) {
      alert("Veuillez ajouter au moins une pièce justificative.");
      return;
    }

    // Create FormData for file upload
    const formDataWithFiles = new FormData();
    formDataWithFiles.append('decesData', JSON.stringify(formData));
    selectedFiles.forEach((file) => {
      formDataWithFiles.append('files', file);
    });

    try {
      const response = await fetch('https://app.falconmarketing.fr:3004/deces', {
        method: 'POST',
        body: formDataWithFiles
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      alert('Décès ajouté avec succès!');
      // Optionally redirect or clear form
    } catch (error) {
      console.error(error);
      alert('Erreur lors de l\'ajout du décès');
    }
  };

  useEffect(() => {
    const fetchDelegates = async () => {
      try {
        const response = await axios.get('https://app.falconmarketing.fr:3004/delegates');
        setDelegates(response.data.delegateNames);
      } catch (error) {
        console.error('Error fetching delegates:', error);
      }
    };
    fetchDelegates();
  }, []);

  useEffect(() => {
    if (location.state) {
      console.log('Props passées à FicheDeces:', location.state);
      setFormData({
        ...formData,
        nomDefunt: location.state.mrName || location.state.mrsName || '',
      });
    }
  }, [location.state]);
  

  // Ajout des valeurs de couleur pour le mode sombre/clair
  const bgBox = useColorModeValue('white', 'gray.800');
  const headingColor = useColorModeValue('gray.800', 'white');
  const inputBg = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const labelColor = useColorModeValue('gray.700', 'gray.200');
  const placeholderColor = useColorModeValue('gray.400', 'gray.500');
  const helperTextColor = useColorModeValue('gray.500', 'gray.400');

  return (
    <Box 
      p={{ base: 4, md: 8 }} 
      bg={bgBox} 
      mt={20} 
      borderRadius={8}
      boxShadow="lg"
    >
      <Heading mb={6} size="xl" textAlign="center" color={headingColor}>
        Dépenses décès
      </Heading>
      <VStack spacing={6} align="stretch">
        <FormControl isRequired>
          <FormLabel color={labelColor}>Délégués :</FormLabel>
          <Select
            name="delegue"
            value={formData.delegue}
            onChange={handleChange}
            bg={inputBg}
            borderColor={borderColor}
            _hover={{ borderColor: borderColor }}
            _placeholder={{ color: placeholderColor }}
            required
          >
            <option value="">Sélectionnez un délégué</option>
            {delegates.map((delegate) => (
              <option key={delegate} value={delegate}>
                {delegate}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl isRequired>
          <FormLabel color={labelColor}>Décès de :</FormLabel>
          <Input
            name="nomDefunt"
            value={formData.nomDefunt}
            onChange={handleChange}
            bg={inputBg}
            borderColor={borderColor}
            _hover={{ borderColor: borderColor }}
            _placeholder={{ color: placeholderColor }}
            required
          />
        </FormControl>

        <FormControl display="flex" alignItems="center">
          <Checkbox 
            name="enfant" 
            isChecked={formData.enfant} 
            onChange={handleChange}
            colorScheme="blue"
          >
            <Text color={labelColor}>Enfant</Text>
          </Checkbox>
        </FormControl>

        <FormControl isRequired>
          <FormLabel color={labelColor}>Date du décès :</FormLabel>
          <Input
            name="dateDecesLieu"
            value={formData.dateDecesLieu ? format(new Date(formData.dateDecesLieu), 'yyyy-MM-dd') : ''}
            onChange={handleChange}
            type="date"
            required
          />
          <FormHelperText color={helperTextColor}>
            Ce champ est obligatoire.
          </FormHelperText>
        </FormControl>

        <FormControl isRequired>
          <FormLabel color={labelColor}>Lieu du décès (autre) :</FormLabel>
          <Input
            name="lieuDeces2"
            value={formData.lieuDeces2}
            onChange={handleChange}
            required
          />
          <FormHelperText color={helperTextColor}>
            Ce champ est obligatoire.
          </FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel color={labelColor}>Lieu :</FormLabel>
          <Stack direction={{ base: "column", sm: "row" }} spacing={4}>
            <Checkbox 
              name="lieuDeces" 
              value="Maison" 
              isChecked={formData.lieuDeces === 'Maison'} 
              onChange={handleChange}
            >
              Maison
            </Checkbox>
            <Checkbox 
              name="lieuDeces" 
              value="Hôpital" 
              isChecked={formData.lieuDeces === 'Hôpital'} 
              onChange={handleChange}
            >
              Hôpital
            </Checkbox>
            <Checkbox 
              name="lieuDeces" 
              value="Algérie" 
              isChecked={formData.lieuDeces === 'Algérie'} 
              onChange={handleChange}
            >
              Algérie
            </Checkbox>
          </Stack>
        </FormControl>

        <FormControl isRequired>
          <FormLabel color={labelColor}>Enterrement :</FormLabel>
          <Stack direction={{ base: "column", sm: "row" }} spacing={4}>
            <Checkbox 
              name="lieuEnterrement" 
              value="France" 
              isChecked={formData.lieuEnterrement === 'France'} 
              onChange={handleChange}
            >
              France
            </Checkbox>
            <Checkbox 
              name="lieuEnterrement" 
              value="Algérie" 
              isChecked={formData.lieuEnterrement === 'Algérie'} 
              onChange={handleChange}
            >
              Algérie
            </Checkbox>
            <Checkbox 
              name="lieuEnterrement" 
              value="Autre" 
              isChecked={formData.lieuEnterrement === 'Autre'} 
              onChange={handleChange}
            >
              Autre
            </Checkbox>
          </Stack>
          <FormHelperText color={helperTextColor}>
            Ce champ est obligatoire.
          </FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel color={labelColor}>Funérarium :</FormLabel>
          <Input name="funerarium" value={formData.funerarium} onChange={handleChange} type="number" />
          <InputRightElement children="€" />
          <FormHelperText color={helperTextColor}>
            Sur facture pompes funèbres
          </FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel color={labelColor}>Toilette :</FormLabel>
          <Input name="toilette" value={formData.toilette} onChange={handleChange} type="number" />
          <InputRightElement children="€" />
          <FormHelperText color={helperTextColor}>
            Sur facture pompes funèbres
          </FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel color={labelColor}>Pompes funèbres :</FormLabel>
          <Input name="pompesFunebres" value={formData.pompesFunebres} onChange={handleChange} type="number" />
          <InputRightElement children="€" />
        </FormControl>

        <FormControl>
          <FormLabel color={labelColor}>Billet accompagnement :</FormLabel>
          <Input name="billetAccompagnement" value={formData.billetAccompagnement} onChange={handleChange} type="number" />
          <InputRightElement children="€" />
        </FormControl>

        <FormControl>
          <FormLabel color={labelColor}>Traitement :</FormLabel>
          <Input name="traitement" value={formData.traitement} onChange={handleChange} type="number" />
          <InputRightElement children="€" />
          <FormHelperText color={helperTextColor}>
            Sur facture pompes funèbres
          </FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel color={labelColor}>Repas :</FormLabel>
          <Input name="repas" value={formData.repas} onChange={handleChange} type="number" />
          <InputRightElement children="€" />
        </FormControl>

        <FormControl>
          <FormLabel color={labelColor}>Concession :</FormLabel>
          <Input name="concession" value={formData.concession} onChange={handleChange} type="number" />
          <InputRightElement children="€" />
          <FormHelperText color={helperTextColor}>
            Sur facture pompes funèbres
          </FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel color={labelColor}>Décès en Algérie :</FormLabel>
          <Input name="decesAlgerie" value={formData.decesAlgerie} onChange={handleChange} type="number" />
          <InputRightElement children="€" />
        </FormControl>

        <FormControl>
          <FormLabel color={labelColor}>Total :</FormLabel>
          <Input name="total" value={formData.total} onChange={handleChange} type="number" />
        </FormControl>

        <FormControl>
          <FormLabel color={labelColor}>Observations de la famille :</FormLabel>
          <Textarea
            name="observations"
            value={formData.observations}
            onChange={handleChange}
            bg={inputBg}
            borderColor={borderColor}
            _hover={{ borderColor: borderColor }}
            _placeholder={{ color: placeholderColor }}
          />
        </FormControl>

        <FormControl>
          <FormLabel color={labelColor}>Pièces justificatives</FormLabel>
          <Input
            type="file"
            multiple
            onChange={(e) => setSelectedFiles(Array.from(e.target.files))}
            accept="application/pdf,image/*"
          />
          {selectedFiles.length > 0 && (
            <VStack mt={2} align="stretch">
              {selectedFiles.map((file, index) => (
                <Text key={index}>{file.name}</Text>
              ))}
            </VStack>
          )}
        </FormControl>
      </VStack>
      <Flex justify="center" mt={6}>
        <Button 
          onClick={handleSubmit} 
          colorScheme="blue" 
          size="lg"
          _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
        >
          Ajouter le décès
        </Button>
      </Flex>
    </Box>
  );
};

const FichePDF = ({ formData }) => (
  <Document>
    <Page style={styles.page}>
      <Text style={styles.heading}>Fiche de Décès</Text>

      {/* Informations générales - Essayez de condenser ces informations si possible */}
      <View style={styles.section}>
        <Text style={styles.sectionHeading}>Informations générales</Text>
        <Text style={styles.label}>Délégués :</Text>
        <Text style={styles.value}>{formData.delegue}</Text>
        <Text style={styles.label}>Décès de :</Text>
        <Text style={styles.value}>{formData.nomDefunt} {formData.enfant ? '(enfant)' : ''}</Text>
        <Text style={styles.label}>Date du décès :</Text>
        <Text style={styles.value}>{new Date(formData.dateDecesLieu).toLocaleDateString()}</Text>

        <Text style={styles.label}>Lieu du décès (autre) :</Text>
        <Text style={styles.value}>{formData.lieuDeces2}</Text>
        <Text style={styles.label}>Lieu :</Text>
        <Text style={styles.value}>{formData.lieuDeces}</Text>
        <Text style={styles.label}>Enterrement :</Text>
        <Text style={styles.value}>{formData.lieuEnterrement}</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionHeading}>Dépenses</Text>
        <View style={{ flexDirection: 'row', marginBottom: 5 }}>
          <Text style={[styles.label, { width: '70%' }]}>Description</Text>
          <Text style={[styles.label, { width: '30%', textAlign: 'right' }]}>Montant</Text>
        </View>
        {[
          { label: "Funérarium", value: formData.funerarium },
          { label: "Toilette", value: formData.toilette },
          { label: "Pompes funèbres", value: formData.pompesFunebres },
          { label: "Billet accompagnement", value: formData.billetAccompagnement },
          { label: "Traitement", value: formData.traitement },
          { label: "Repas", value: formData.repas },
          { label: "Concession", value: formData.concession },
          { label: "Décès en Algérie", value: formData.decesAlgerie },
        ].map((item, index) => (
          <View key={index} style={{ flexDirection: 'row', marginBottom: 3 }}>
            <Text style={[styles.value, { width: '70%' }]}>{item.label}</Text>
            <Text style={[styles.value, { width: '30%', textAlign: 'right' }]}>{`${item.value} €`}</Text>
          </View>
        ))}
      </View>

      {/* Totaux et observations - Essayer de garder ces informations concises */}
      <View style={styles.section}>
        <Text style={styles.sectionHeading}>Totaux</Text>
        <Text style={styles.value}>{`Total: ${formData.total} €`}</Text>
        <Text style={styles.value}>{`Montant restant pour la famille: ${formData.montantRestant} €`}</Text>
      </View>
      <Text style={styles.sectionHeading}>Observations de la famille</Text>
      <Text style={styles.value}>{formData.observations}</Text>

      {/* Footer */}
      <Text style={styles.footer}>Association El Mektoub</Text>
    </Page>
  </Document>
);

export default FicheDeces;

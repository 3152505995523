import React, { useContext, useEffect, useState, useCallback, useMemo } from "react";
import {
  Box,
  useColorMode,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Button,
  Flex,
  useDisclosure,
  IconButton,
  Heading,
  Select,
  HStack,
  Tooltip,
  Avatar,
  SimpleGrid,
  Stack,
  Checkbox,
  Spinner,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
  InputGroup,
  InputLeftElement,
  Input,
} from "@chakra-ui/react";
import { AuthContext } from "contexts/AuthContext";
import { MdAdd, MdRemoveRedEye, MdEdit, MdDelete, MdSearch } from "react-icons/md";
import AddMemberModal from "./AddMemberModal";
import AdherentDetailsModal from "./AdherentDetailsModal";
import EditMemberModal from "./EditMemberModal";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";

// Import libraries for export
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { debounce } from "lodash";

const MemberList = () => {
  const [searchValue, setSearchValue] = useState("");
  const { saveMemberToFirestore, getUserData } = useContext(AuthContext);
  const [members, setMembers] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedAdherent, setSelectedAdherent] = useState(null);
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const tableBgColor = useColorModeValue("white", "gray.700");
  const tableHeaderBgColor = useColorModeValue("gray.50", "gray.700");
  const tableRowHoverBg = useColorModeValue("gray.50", "gray.600");
  const inputBgColor = useColorModeValue("white", "gray.700");
  const statusColorMapping = {
    "À jour": {
      bg: useColorModeValue("green.100", "green.800"),
      text: useColorModeValue("green.800", "green.100")
    },
    "Radié": {
      bg: useColorModeValue("red.100", "red.800"),
      text: useColorModeValue("red.800", "red.100")
    },
    "En retard": {
      bg: useColorModeValue("orange.100", "orange.800"),
      text: useColorModeValue("orange.800", "orange.100")
    }
  };
  const { colorMode } = useColorMode();
  const [isEditMemberModalOpen, setIsEditMemberModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [membersPerPage, setMembersPerPage] = useState(10);
  const [selectedDelegate, setSelectedDelegate] = useState("");
  const [delegates, setDelegates] = useState([]);
  const [selectedContributionStatus, setSelectedContributionStatus] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "id", direction: "descending" });
  const [loadingMembers, setLoadingMembers] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const {
    isOpen: isDeleteDialogOpen,
    onOpen: onDeleteDialogOpen,
    onClose: onDeleteDialogClose,
  } = useDisclosure();
  const cancelRef = React.useRef();
  const toast = useToast();
  const { user } = useContext(AuthContext);

  const fetchDelegates = useCallback(async () => {
    try {
      const response = await fetch("https://app.falconmarketing.fr:3004/delegates");
      const data = await response.json();
      setDelegates(data.delegateNames);
    } catch (error) {
      console.error("Erreur lors de la récupération des délégués:", error);
    }
  }, []);

  const fetchMembers = useCallback(async () => {
    try {
      const response = await fetch("https://app.falconmarketing.fr:3004/adherents");
      const data = await response.json();
      setMembers(data.adherents);
    } catch (error) {
      console.error("Erreur lors de la récupération des adhérents:", error);
      toast({
        title: "Erreur",
        description: "Impossible de charger les adhérents.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    fetchDelegates();
    fetchMembers();
    const intervalId = setInterval(() => {
      fetchMembers();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [fetchDelegates, fetchMembers]);

  const handleSearchChange = debounce((e) => {
    setSearchValue(e.target.value);
    setCurrentPage(1);
  }, 300);

  const handleOpenModal = useCallback(
    (adherent) => {
      setSelectedAdherent(adherent);
      onOpen();
    },
    [onOpen]
  );

  const handleSaveMember = async (memberData) => {
    await saveMemberToFirestore(memberData);
    setSelectedAdherent(null);
    handleCloseAddMemberModal();
    fetchMembers();
  };

  const handleOpenAddMemberModal = () => setIsAddMemberModalOpen(true);
  const handleCloseAddMemberModal = () => setIsAddMemberModalOpen(false);

  const handleOpenEditMemberModal = useCallback((adherent) => {
    setSelectedAdherent(adherent);
    setIsEditMemberModalOpen(true);
  }, []);

  const updateMemberStatus = async (memberId, newStatus) => {
    if (!user || !user.profileData) {
      throw new Error("Utilisateur non connecté");
    }

    const response = await fetch(
      `https://app.falconmarketing.fr:3004/adherents/${memberId}/status`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          status: newStatus,
          responsableId: user.uid,
          responsableNom: user.profileData.nom || '',
          responsablePrenom: user.profileData.prenom || ''
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Erreur lors de la mise à jour du statut de l'adhérent");
    }

    return response.json();
  };

  const handleCheckboxChange = async (memberId, isChecked) => {
    if (!user || !user.profileData) {
      toast({
        title: "Erreur",
        description: "Vous devez être connecté pour effectuer cette action.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    console.log("Informations du responsable:", {
      id: user.uid,
      nom: user.profileData.nom,
      prenom: user.profileData.prenom
    });

    setLoadingMembers(prev => ({ ...prev, [memberId]: true }));
    try {
      const newStatus = isChecked ? "À jour" : "En retard";
      
      const response = await fetch(
        `https://app.falconmarketing.fr:3004/adherents/${memberId}/status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            status: newStatus,
            responsableId: user.uid,
            responsableNom: user.profileData?.nom || '',
            responsablePrenom: user.profileData?.prenom || '',
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      toast({
        title: "Succès",
        description: "Statut de cotisation mis à jour.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchMembers();
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut:", error);
      toast({
        title: "Erreur",
        description: "Impossible de mettre à jour le statut.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoadingMembers(prev => ({ ...prev, [memberId]: false }));
    }
  };

  const getStatusColor = (status) => {
    return statusColorMapping[status] || bgColor;
  };

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const filteredMembers = useMemo(() => {
    let filtered = [...members];

    if (searchValue) {
      filtered = filtered.filter((member) =>
        member.mrName.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    if (selectedDelegate) {
      filtered = filtered.filter((member) => member.delegateName === selectedDelegate);
    }

    if (selectedContributionStatus) {
      filtered = filtered.filter(
        (member) => member.contributionStatus === selectedContributionStatus
      );
    }

    return filtered;
  }, [members, searchValue, selectedDelegate, selectedContributionStatus]);

  const sortedMembers = useMemo(() => {
    const sorted = [...filteredMembers];
    if (sortConfig.key) {
      sorted.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sorted;
  }, [filteredMembers, sortConfig]);

  const indexOfLastMember = currentPage * membersPerPage;
  const indexOfFirstMember = indexOfLastMember - membersPerPage;
  const currentMembers = sortedMembers.slice(indexOfFirstMember, indexOfLastMember);

  const totalPages = Math.ceil(sortedMembers.length / membersPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const exportToExcel = () => {
    try {
      const excelData = filteredMembers.map((member) => ({
        Numéro: member.id,
        "Nom et Prénom": member.mrName,
        Adresse: member.address,
        Téléphone: member.phone,
        "Numéro SS": member.socialSecurityNumber,
        Délégué: member.delegateName,
        Cotisation: member.cotisation,
        Statut: member.contributionStatus,
      }));

      const worksheet = XLSX.utils.json_to_sheet(excelData);
      const workbook = {
        Sheets: { Adhérents: worksheet },
        SheetNames: ["Adhérents"],
      };

      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
      saveAs(blob, "liste_adherents.xlsx");
    } catch (error) {
      console.error("Erreur lors de l'exportation:", error);
      toast({
        title: "Erreur",
        description: "Impossible d'exporter les données.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDelete = (member) => {
    setMemberToDelete(member);
    onDeleteDialogOpen();
  };

  const confirmDelete = async () => {
    try {
      await deleteMember(memberToDelete.id);
      toast({
        title: "Supprimé",
        description: "Membre supprimé avec succès.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchMembers();
    } catch (error) {
      console.error("Erreur lors de la suppression:", error);
      toast({
        title: "Erreur",
        description: "Impossible de supprimer le membre.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      onDeleteDialogClose();
    }
  };

  const deleteMember = async (memberId) => {
    const response = await fetch(`https://app.falconmarketing.fr:3004/adherents/${memberId}`, {
      method: "DELETE",
    });
    if (!response.ok) {
      throw new Error("Erreur lors de la suppression du membre");
    }
  };

  // Nouvelle fonction pour gérer la sélection de toutes les cases
  const handleSelectAllChange = async (isChecked) => {
    if (!user || !user.profileData) {
      toast({
        title: "Erreur",
        description: "Vous devez être connecté pour effectuer cette action.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const newStatus = isChecked ? "À jour" : "En retard";
    setIsLoading(true);
    try {
      await Promise.all(
        currentMembers.map(async (member) => {
          await updateMemberStatus(member.id, newStatus);
          await fetch(`https://app.falconmarketing.fr:3004/cotisations`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              memberId: member.id,
              status: newStatus,
              responsableId: user.uid,
              responsableNom: user.profileData.nom || '',
              responsablePrenom: user.profileData.prenom || '',
              dateEncaissement: new Date().toISOString()
            }),
          });
        })
      );
      
      toast({
        title: "Succès",
        description: `Statut de cotisation mis à jour pour ${isChecked ? "tous les membres" : "aucun des membres"} affichés.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchMembers();
    } catch (error) {
      console.error("Erreur lors de la mise à jour des statuts de cotisation:", error);
      toast({
        title: "Erreur",
        description: "Impossible de mettre à jour les statuts de cotisation pour tous les membres.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderPaginationButtons = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) pageNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) pageNumbers.push("...");
      pageNumbers.push(totalPages);
    }

    return pageNumbers.map((number, index) => {
      if (number === "...") {
        return <Text key={`ellipsis-${index}`}>...</Text>;
      }
      return (
        <Button
          key={number}
          onClick={() => paginate(number)}
          variant={currentPage === number ? "solid" : "ghost"}
          colorScheme="teal"
          size="sm"
        >
          {number}
        </Button>
      );
    });
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Box 
        p={4} 
        bg={bgColor} 
        borderRadius="md" 
        boxShadow="md"
        borderColor={borderColor}
        color={textColor}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb={6}
          flexDirection={{ base: "column", md: "row" }}
        >
          <Heading size="lg" mb={{ base: 4, md: 0 }}>Liste des adhérents</Heading>
          <Stack direction={{ base: "column", md: "row" }} spacing={4}>
            <Button
              leftIcon={<MdAdd />}
              colorScheme="teal"
              variant="solid"
              onClick={handleOpenAddMemberModal}
              size="sm"
            >
              Ajouter un membre
            </Button>
            <Button
              leftIcon={<MdEdit />}
              colorScheme="green"
              variant="outline"
              onClick={exportToExcel}
              size="sm"
            >
              Exporter en Excel
            </Button>
          </Stack>
        </Flex>

        {/* Affichage des statistiques */}
        <Box mb={4}>
          <SimpleGrid columns={{ base: 1, md: 4 }} spacing={4}>
            <Box>
              <Text>
                Total des adhérents : <strong>{filteredMembers.length}</strong>
              </Text>
            </Box>
            <Box>
              <Text>
                À jour :{" "}
                <strong>
                  {filteredMembers.filter((member) => member.contributionStatus === "À jour").length}
                </strong>
              </Text>
            </Box>
            <Box>
              <Text>
                En retard :{" "}
                <strong>
                  {filteredMembers.filter((member) => member.contributionStatus === "En retard").length}
                </strong>
              </Text>
            </Box>
            <Box>
              <Text>
                Radiés :{" "}
                <strong>
                  {filteredMembers.filter((member) => member.contributionStatus === "Radié").length}
                </strong>
              </Text>
            </Box>
          </SimpleGrid>
        </Box>

        <Stack direction={{ base: "column", md: "row" }} spacing={4} mb={4}>
          <Box flex="1" minWidth="200px">
            <Text mb={2}>Recherche :</Text>
            <InputGroup>
              <InputLeftElement pointerEvents="none" children={<MdSearch color="gray.300" />} />
              <Input
                placeholder="Tapez pour rechercher..."
                onChange={handleSearchChange}
                bg={colorMode === "dark" ? "gray.800" : "gray.100"}
                color={colorMode === "dark" ? "white" : "black"}
              />
            </InputGroup>
          </Box>
          <Box flex="1" minWidth="200px">
            <Text mb={2}>Filtre par délégué :</Text>
            <Select
              placeholder="Sélectionnez un délégué"
              value={selectedDelegate}
              onChange={(e) => {
                setSelectedDelegate(e.target.value);
                setCurrentPage(1);
              }}
              bg={colorMode === "dark" ? "gray.800" : "gray.100"}
              color={colorMode === "dark" ? "white" : "black"}
            >
              <option value="">Tous les délégués</option>
              {delegates
                .sort((a, b) => a.localeCompare(b))
                .map((delegate) => (
                  <option key={delegate} value={delegate}>
                    {delegate}
                  </option>
                ))}
            </Select>
          </Box>
          <Box flex="1" minWidth="200px">
            <Text mb={2}>Filtre par statut de cotisation :</Text>
            <Select
              placeholder="Sélectionnez un statut"
              value={selectedContributionStatus}
              onChange={(e) => {
                setSelectedContributionStatus(e.target.value);
                setCurrentPage(1);
              }}
              bg={colorMode === "dark" ? "gray.800" : "gray.100"}
              color={colorMode === "dark" ? "white" : "black"}
            >
              <option value="">Tous les statuts</option>
              <option value="À jour">À jour</option>
              <option value="En retard">En retard</option>
              <option value="Radié">Radié</option>
            </Select>
          </Box>
          <Box flex="1" minWidth="200px">
            <Text mb={2}>Adhérents par page :</Text>
            <Select
              value={membersPerPage}
              onChange={(e) => setMembersPerPage(Number(e.target.value))}
              bg={colorMode === "dark" ? "gray.800" : "gray.100"}
              color={colorMode === "dark" ? "white" : "black"}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </Select>
          </Box>
        </Stack>

        <Box overflowX="auto" width="100%">
          {isLoading ? (
            <Flex justify="center" align="center" height="200px">
              <Spinner size="xl" />
            </Flex>
          ) : (
            <MemberTable
              members={currentMembers}
              handleOpenModal={handleOpenModal}
              handleOpenEditMemberModal={handleOpenEditMemberModal}
              handleCheckboxChange={handleCheckboxChange}
              handleSelectAllChange={handleSelectAllChange}
              getStatusColor={getStatusColor}
              handleDelete={handleDelete}
              requestSort={requestSort}
              sortConfig={sortConfig}
              loadingMembers={loadingMembers}
              textColor={textColor}
              borderColor={borderColor}
              tableHeaderBgColor={tableHeaderBgColor}
              tableRowHoverBg={tableRowHoverBg}
              inputBgColor={inputBgColor}
              tableBgColor={tableBgColor}
              bgColor={bgColor}
              statusColorMapping={statusColorMapping}
            />
          )}
        </Box>

        {/* Pagination améliorée */}
        <Flex justifyContent="center" mt={8}>
          <HStack spacing={2} flexWrap="wrap">
            <Button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              variant="ghost"
              colorScheme="teal"
              size="sm"
            >
              Précédent
            </Button>
            {renderPaginationButtons().map((button, index) => (
              <Box key={index}>{button}</Box>
            ))}
            <Button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              variant="ghost"
              colorScheme="teal"
              size="sm"
            >
              Suivant
            </Button>
          </HStack>
        </Flex>

        <AddMemberModal
          isOpen={isAddMemberModalOpen}
          onClose={handleCloseAddMemberModal}
          onSave={handleSaveMember}
          selectedAdherent={selectedAdherent}
          size={{ base: "full", md: "md" }} // Taille responsive
        />
        {selectedAdherent && (
          <AdherentDetailsModal
            isOpen={isOpen}
            onClose={onClose}
            adherent={selectedAdherent}
            size={{ base: "full", md: "lg" }} // Taille responsive
          />
        )}
        <EditMemberModal
          isOpen={isEditMemberModalOpen}
          onClose={() => setIsEditMemberModalOpen(false)}
          adherent={selectedAdherent}
          onSave={handleSaveMember}
          size={{ base: "full", md: "md" }} // Taille responsive
        />

        <AlertDialog
          isOpen={isDeleteDialogOpen}
          leastDestructiveRef={cancelRef}
          onClose={onDeleteDialogClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader>Confirmer la suppression</AlertDialogHeader>
              <AlertDialogBody>
                Êtes-vous sûr de vouloir supprimer ce membre ? Cette action est irréversible.
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onDeleteDialogClose}>
                  Annuler
                </Button>
                <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                  Supprimer
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    </Box>
  );
};

const MemberTable = React.memo(({
  members,
  handleOpenModal,
  handleOpenEditMemberModal,
  handleCheckboxChange,
  handleSelectAllChange,
  getStatusColor,
  handleDelete,
  requestSort,
  sortConfig,
  loadingMembers,
  textColor,
  borderColor,
  tableHeaderBgColor,
  tableRowHoverBg,
  inputBgColor,
  tableBgColor,
  bgColor,
  statusColorMapping,
}) => {
  const getSortIcon = (columnName) => {
    if (sortConfig.key === columnName) {
      return sortConfig.direction === "ascending" ? <TriangleUpIcon /> : <TriangleDownIcon />;
    }
    return null;
  };

  // Déterminer si toutes les cases sont cochées
  const isAllSelected = members.every((member) => member.contributionStatus === "À jour");
  // Déterminer si certaines cases sont cochées
  const isIndeterminate = members.some((member) => member.contributionStatus === "À jour") && !isAllSelected;

  return (
    <>
      <Box display={{ base: "none", md: "block" }} width="100%">
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th 
                onClick={() => requestSort("id")} 
                cursor="pointer"
                bg={tableHeaderBgColor}
                color={textColor}
                borderColor={borderColor}
              >
                Numéro {getSortIcon("id")}
              </Th>
              <Th onClick={() => requestSort("mrName")} cursor="pointer">
                Nom et Prénom {getSortIcon("mrName")}
              </Th>
              <Th>Adresse</Th>
              <Th>Téléphone</Th>
              <Th>Numéro SS</Th>
              <Th>Délégué</Th>
              <Th>Cotisation</Th>
              <Th onClick={() => requestSort("contributionStatus")} cursor="pointer">
                Statut {getSortIcon("contributionStatus")}
              </Th>
              <Th>Actions</Th>
              <Th>
                <Checkbox
                  isChecked={isAllSelected}
                  isIndeterminate={isIndeterminate}
                  onChange={(e) => handleSelectAllChange(e.target.checked)}
                  colorScheme="teal"
                />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {members.map((member) => (
              <MemberRow
                key={member.id}
                member={member}
                handleOpenModal={handleOpenModal}
                handleOpenEditMemberModal={handleOpenEditMemberModal}
                handleCheckboxChange={handleCheckboxChange}
                getStatusColor={getStatusColor}
                handleDelete={handleDelete}
                loadingMembers={loadingMembers}
                textColor={textColor}
                borderColor={borderColor}
                hoverBg={tableRowHoverBg}
                inputBgColor={inputBgColor}
                statusColorMapping={statusColorMapping}
              />
            ))}
          </Tbody>
        </Table>
      </Box>

      <Box display={{ base: "block", md: "none" }} width="100%">
        {members.map((member) => (
          <MemberCard
            key={member.id}
            member={member}
            handleOpenModal={handleOpenModal}
            handleOpenEditMemberModal={handleOpenEditMemberModal}
            handleCheckboxChange={handleCheckboxChange}
            getStatusColor={getStatusColor}
            handleDelete={handleDelete}
            loadingMembers={loadingMembers}
            textColor={textColor}
            borderColor={borderColor}
            bgColor={tableBgColor}
            inputBgColor={inputBgColor}
            statusColorMapping={statusColorMapping}
          />
        ))}
      </Box>
    </>
  );
});

const MemberRow = React.memo(({
  member,
  handleOpenModal,
  handleOpenEditMemberModal,
  handleCheckboxChange,
  getStatusColor,
  handleDelete,
  loadingMembers,
  textColor,
  borderColor,
  hoverBg,
  inputBgColor,
  statusColorMapping,
}) => {
  const [isEditingCotisation, setIsEditingCotisation] = useState(false);
  const [cotisationValue, setCotisationValue] = useState(member.cotisation || '');
  const [isUpdatingCotisation, setIsUpdatingCotisation] = useState(false);
  const toast = useToast();

  const handleCotisationClick = () => {
    setIsEditingCotisation(true);
  };

  const handleCotisationChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value)) {
      setCotisationValue(value);
    }
  };

  const handleCotisationBlur = async () => {
    if (cotisationValue !== member.cotisation) {
      setIsUpdatingCotisation(true);
      try {
        const response = await fetch(
          `https://app.falconmarketing.fr:3004/adherents/${member.id}/cotisation`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ cotisation: parseFloat(cotisationValue) }),
          }
        );

        if (!response.ok) {
          throw new Error("Erreur lors de la mise à jour de la cotisation");
        }
        toast({
          title: "Succès",
          description: "Cotisation mise à jour avec succès.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        // Optionnel: mettre à jour le membre dans l'état local si nécessaire
      } catch (error) {
        console.error("Erreur:", error);
        toast({
          title: "Erreur",
          description: "Impossible de mettre à jour la cotisation.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setCotisationValue(member.cotisation);
      } finally {
        setIsUpdatingCotisation(false);
      }
    }
    setIsEditingCotisation(false);
  };

  // Définir les couleurs au niveau du composant
  const greenBg = useColorModeValue("green.50", "green.900");
  const greenHoverBg = useColorModeValue("green.100", "green.800");
  const orangeBg = useColorModeValue("orange.50", "orange.900");
  const orangeHoverBg = useColorModeValue("orange.100", "orange.800");
  const redBg = useColorModeValue("red.50", "red.900");
  const redHoverBg = useColorModeValue("red.100", "red.800");

  const getRowBgColor = () => {
    const status = member.contributionStatus;
    switch(status) {
      case "À jour":
        return {
          bg: greenBg,
          _hover: { bg: greenHoverBg }
        };
      case "En retard":
        return {
          bg: orangeBg,
          _hover: { bg: orangeHoverBg }
        };
      case "Radié":
        return {
          bg: redBg,
          _hover: { bg: redHoverBg }
        };
      default:
        return {
          bg: 'transparent',
          _hover: { bg: hoverBg }
        };
    }
  };

  const rowStyles = getRowBgColor();

  return (
    <Tr 
      borderColor={borderColor}
      {...rowStyles}
      transition="background-color 0.2s"
    >
      <Td color={textColor}>{member.id}</Td>
      <Td color={textColor}>{member.mrName}</Td>
      <Td color={textColor}>{member.address}</Td>
      <Td color={textColor}>{member.phone}</Td>
      <Td color={textColor}>{member.socialSecurityNumber}</Td>
      <Td color={textColor}>{member.delegateName}</Td>
      <Td>
        {isUpdatingCotisation ? (
          <Spinner size="sm" />
        ) : isEditingCotisation ? (
          <Input
            size="sm"
            value={cotisationValue}
            onChange={handleCotisationChange}
            onBlur={handleCotisationBlur}
            bg={inputBgColor}
            color={textColor}
            borderColor={borderColor}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleCotisationBlur();
              }
            }}
            autoFocus
          />
        ) : (
          <Text
            cursor="pointer"
            onClick={handleCotisationClick}
            _hover={{ textDecoration: "underline" }}
            color={textColor}
          >
            {member.cotisation || '0'}
          </Text>
        )}
      </Td>
      <Td>
        <Box
          px={2}
          py={1}
          borderRadius="md"
          bg={statusColorMapping[member.contributionStatus]?.bg}
          color={statusColorMapping[member.contributionStatus]?.text}
          textAlign="center"
          fontSize="sm"
          fontWeight="medium"
        >
          {member.contributionStatus}
        </Box>
      </Td>
      <Td>
        <HStack spacing={1}>
          <Tooltip label="Voir les détails">
            <IconButton
              aria-label="Voir les détails"
              icon={<MdRemoveRedEye />}
              variant="ghost"
              size="xs"
              onClick={() => handleOpenModal(member)}
            />
          </Tooltip>
          <Tooltip label="Modifier">
            <IconButton
              aria-label="Modifier"
              icon={<MdEdit />}
              variant="ghost"
              size="xs"
              onClick={() => handleOpenEditMemberModal(member)}
            />
          </Tooltip>
          <Tooltip label="Supprimer">
            <IconButton
              aria-label="Supprimer"
              icon={<MdDelete />}
              variant="ghost"
              size="xs"
              colorScheme="red"
              onClick={() => handleDelete(member)}
            />
          </Tooltip>
        </HStack>
      </Td>
      <Td>
        <Flex alignItems="center" justifyContent="center">
          {loadingMembers[member.id] ? (
            <Spinner size="sm" color="teal.500" />
          ) : (
            <Checkbox
              isChecked={member.contributionStatus === "À jour"}
              onChange={(e) => handleCheckboxChange(member.id, e.target.checked)}
              colorScheme="teal"
              size="sm"
            />
          )}
        </Flex>
      </Td>
    </Tr>
  );
});

const MemberCard = React.memo(({
  member,
  handleOpenModal,
  handleOpenEditMemberModal,
  handleCheckboxChange,
  getStatusColor,
  handleDelete,
  loadingMembers,
  textColor,
  borderColor,
  bgColor,
  inputBgColor,
  statusColorMapping,
}) => {
  const [isEditingCotisation, setIsEditingCotisation] = useState(false);
  const [cotisationValue, setCotisationValue] = useState(member.cotisation || '');
  const [isUpdatingCotisation, setIsUpdatingCotisation] = useState(false);
  const toast = useToast();

  const handleCotisationClick = () => {
    setIsEditingCotisation(true);
  };

  const handleCotisationChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value)) {
      setCotisationValue(value);
    }
  };

  const handleCotisationBlur = async () => {
    if (cotisationValue !== member.cotisation) {
      setIsUpdatingCotisation(true);
      try {
        const response = await fetch(
          `https://app.falconmarketing.fr:3004/adherents/${member.id}/cotisation`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ cotisation: parseFloat(cotisationValue) }),
          }
        );

        if (!response.ok) {
          throw new Error("Erreur lors de la mise à jour de la cotisation");
        }
        toast({
          title: "Succès",
          description: "Cotisation mise à jour avec succès.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Erreur:", error);
        toast({
          title: "Erreur",
          description: "Impossible de mettre à jour la cotisation.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setCotisationValue(member.cotisation);
      } finally {
        setIsUpdatingCotisation(false);
      }
    }
    setIsEditingCotisation(false);
  };

  const getCardBgColor = () => {
    const status = member.contributionStatus;
    const statusColor = statusColorMapping[status]?.bg;
    
    if (statusColor) {
      return {
        bg: statusColor,
        opacity: 0.15,
        _hover: {
          opacity: 0.25
        }
      };
    }
    
    return {
      bg: bgColor
    };
  };

  const cardStyles = getCardBgColor();

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={4}
      mb={4}
      borderColor={borderColor}
      color={textColor}
      transition="background-color 0.2s"
      {...cardStyles}
    >
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        <Heading size="md">{member.mrName}</Heading>
        <Avatar name={member.mrName} size="sm" />
      </Flex>
      <Stack spacing={2}>
        <Text><strong>Numéro:</strong> {member.id}</Text>
        <Text><strong>Adresse:</strong> {member.address}</Text>
        <Text><strong>Téléphone:</strong> {member.phone}</Text>
        <Text><strong>Numéro SS:</strong> {member.socialSecurityNumber}</Text>
        <Text><strong>Délégué:</strong> {member.delegateName}</Text>
        <Box>
          <Text display="inline"><strong>Cotisation:</strong> </Text>
          {isUpdatingCotisation ? (
            <Spinner size="sm" />
          ) : isEditingCotisation ? (
            <Input
              size="sm"
              value={cotisationValue}
              onChange={handleCotisationChange}
              onBlur={handleCotisationBlur}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleCotisationBlur();
                }
              }}
              width="100px"
              autoFocus
            />
          ) : (
            <Text
              display="inline"
              cursor="pointer"
              onClick={handleCotisationClick}
              _hover={{ textDecoration: "underline" }}
              color={textColor}
            >
              {member.cotisation || '0'}
            </Text>
          )}
        </Box>
        <Box
          px={2}
          py={1}
          borderRadius="md"
          bg={statusColorMapping[member.contributionStatus]?.bg}
          color={statusColorMapping[member.contributionStatus]?.text}
          textAlign="center"
          fontSize="sm"
          fontWeight="medium"
          mt={2}
        >
          {member.contributionStatus}
        </Box>
      </Stack>
      <HStack spacing={2} mt={4}>
        <IconButton
          aria-label="Voir les détails"
          icon={<MdRemoveRedEye />}
          variant="ghost"
          size="sm"
          onClick={() => handleOpenModal(member)}
        />
        <IconButton
          aria-label="Modifier"
          icon={<MdEdit />}
          variant="ghost"
          size="sm"
          onClick={() => handleOpenEditMemberModal(member)}
        />
        <IconButton
          aria-label="Supprimer"
          icon={<MdDelete />}
          variant="ghost"
          size="sm"
          colorScheme="red"
          onClick={() => handleDelete(member)}
        />
        <Checkbox
          isChecked={member.contributionStatus === "À jour"}
          onChange={(e) => handleCheckboxChange(member.id, e.target.checked)}
          colorScheme="teal"
          size="sm"
        />
      </HStack>
    </Box>
  );
});

export default MemberList;

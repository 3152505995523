import React, { useState, useEffect, useRef } from 'react';
import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
  ModalBody, ModalFooter, Button, FormControl, FormLabel, Input, Select, VStack,
  HStack, IconButton, Box, useToast, AlertDialog, AlertDialogBody, AlertDialogFooter,
  AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Flex, Text, Icon, useColorModeValue
} from '@chakra-ui/react';
import { MdAdd, MdDelete, MdPersonAdd, MdUpload, MdDownload } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

const EditMemberModal = ({ isOpen, onClose, adherent, onSave, onDelete }) => {
  const initialMemberState = {
    mrName: '',
    mrsName: '',
    socialSecurityNumber: '',
    socialSecurityNumberLine2: '',
    birthDate: '',
    address: '',
    phone: '',
    email: '',
    nationality: '',
    burialSite: '',
    maritalStatus: '',
    contributionStatus: '',
    delegateName: '',
  };

  const history = useHistory();
  const toast = useToast();

  // Définition des variables de couleurs pour le mode sombre/clair
  const modalBg = useColorModeValue('white', 'gray.800');
  const headerBg = useColorModeValue('blue.500', 'blue.600');
  const boxBg = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const headingColor = useColorModeValue('blue.600', 'blue.200');
  const textColor = useColorModeValue('gray.800', 'whiteAlpha.900');
  const labelColor = useColorModeValue('gray.700', 'gray.200');
  const inputBg = useColorModeValue('white', 'gray.700');
  const placeholderColor = useColorModeValue('gray.400', 'gray.500');
  const hoverBg = useColorModeValue('gray.100', 'gray.600');

  const handleCreateDeathRecord = (mrName, mrsName) => {
    history.push('/admin/deces', { mrName, mrsName });
  };

  const [member, setMember] = useState(initialMemberState);
  const [children, setChildren] = useState([{ name: '', birthDate: '' }]);
  const [childrenToDelete, setChildrenToDelete] = useState([]);
  const [files, setFiles] = useState([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [delegates, setDelegates] = useState([]);
  const onCloseAlert = () => setIsAlertOpen(false);
  const cancelRef = useRef();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);

  const [childToTransfer, setChildToTransfer] = useState(null);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setSelectedFiles(prevFiles => [...prevFiles, ...newFiles]);
    e.target.value = '';
  };

  useEffect(() => {
    const fetchDelegates = async () => {
      const response = await fetch('https://app.falconmarketing.fr:3004/delegates');
      if (response.ok) {
        const data = await response.json();
        setDelegates(data.delegateNames);
      }
    };

    fetchDelegates();
  }, []);

  const handleClose = () => {
    setIsAlertOpen(false);
    onClose();
  };

  const fetchMembers = async () => {
    try {
      const response = await fetch('https://app.falconmarketing.fr:3004/adherents');
      if (response.ok) {
        const data = await response.json();
        // Gérer les données
      } else {
        throw new Error('Échec de la récupération des adhérents');
      }
    } catch (error) {
      console.error('Échec de la récupération des adhérents :', error);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setIsAlertOpen(false);
    }
  }, [isOpen]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    if (name.startsWith('children')) {
      const [_, field, childIndexStr] = name.split('.');
      const childIndex = parseInt(childIndexStr, 10);
      const updatedChildren = [...children];
      updatedChildren[childIndex] = { ...updatedChildren[childIndex], [field]: value };
      setChildren(updatedChildren);
    } else {
      setMember(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleAddChild = () => {
    setChildren([...children, { name: '', birthDate: '' }]);
  };

  const handleRemoveChild = (index) => {
    const child = children[index];
    if (child.id) {
      setChildrenToDelete(prev => [...prev, child.id]);
    }
    const updatedChildren = [...children];
    updatedChildren.splice(index, 1);
    setChildren(updatedChildren);
  };

  useEffect(() => {
    const fetchChildren = async () => {
      if (adherent && adherent.id && isOpen) {
        try {
          const response = await fetch(`https://app.falconmarketing.fr:3004/adherents/${adherent.id}/children`);
          const data = await response.json();
          setChildren(data.children.length > 0 ? data.children : [{ name: '', birthDate: '' }]);
        } catch (error) {
          console.error('Échec de la récupération des enfants :', error);
          setChildren([{ name: '', birthDate: '' }]);
        }
      }
    };

    fetchChildren();

    if (adherent) {
      setMember({ ...adherent, children: undefined });
    } else {
      setMember(initialMemberState);
    }
  }, [adherent, isOpen]);

  const handleSave = async () => {
    try {
      // Suppression des enfants
      await Promise.all(childrenToDelete.map(childId =>
        fetch(`https://app.falconmarketing.fr:3004/children/${childId}`, {
          method: 'DELETE',
        })
      ));
      
      setChildrenToDelete([]);

      // Mise à jour des informations de l'adhérent
      const adherentResponse = await fetch(`https://app.falconmarketing.fr:3004/adherents/${adherent.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(member),
      });

      if (!adherentResponse.ok) {
        throw new Error('Échec de la mise à jour de l\'adhérent');
      }

      // Téléchargement des nouveaux fichiers
      if (selectedFiles.length > 0) {
        const formData = new FormData();
        selectedFiles.forEach(file => {
          formData.append('files', file);
        });

        const fileResponse = await fetch(`https://app.falconmarketing.fr:3004/adherents/${adherent.id}/files`, {
          method: 'POST',
          body: formData,
        });

        if (!fileResponse.ok) {
          throw new Error('Échec du téléchargement des fichiers');
        }
      }

      // Mise à jour des enfants
      await Promise.all(children.map(async (child) => {
        const url = child.id 
          ? `https://app.falconmarketing.fr:3004/children/${child.id}` 
          : `https://app.falconmarketing.fr:3004/children/${adherent.id}`;
        const method = child.id ? 'PUT' : 'POST';

        const childResponse = await fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: child.name,
            birthDate: child.birthDate,
            adherentId: adherent.id,
          }),
        });

        if (!childResponse.ok) {
          throw new Error('Échec de la mise à jour ou de l\'ajout de l\'enfant');
        }
      }));

      toast({
        title: 'Succès',
        description: 'Informations de l\'adhérent mises à jour avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setSelectedFiles([]);
      
      onClose();
    } catch (error) {
      toast({
        title: 'Erreur',
        description: `Une erreur est survenue : ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const calculateContributionAmount = (maritalStatus) => {
    const singleStatus = ['single', 'widowed', 'divorcedWithoutChildren'];
    const marriedOrDivorcedWithChildren = ['married', 'divorcedWithChildren'];
  
    if (singleStatus.includes(maritalStatus)) {
      return 55;
    } else if (marriedOrDivorcedWithChildren.includes(maritalStatus)) {
      return 110;
    } else {
      return 0;
    }
  };
  

  const handleDeleteAdherent = async () => {
    try {
      const response = await fetch(`https://app.falconmarketing.fr:3004/adherents/${adherent.id}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Échec de la suppression de l\'adhérent');
      }
      toast({
        title: 'Adhérent supprimé',
        description: 'L\'adhérent et ses enfants ont été supprimés avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setIsDeleteAlertOpen(false);
      if (typeof onDelete === 'function') {
        onDelete(adherent.id);
      }
      onClose();
    } catch (error) {
      toast({
        title: 'Erreur',
        description: `Une erreur est survenue : ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsDeleteAlertOpen(false);
    }
  };

  const handleCreateAdherentFromChild = async (childId) => {
    try {
      const response = await fetch(`https://app.falconmarketing.fr:3004/children/${childId}/transfer`, {
        method: 'POST',
      });
  
      if (response.ok) {
        toast({
          title: 'Succès',
          description: 'L\'enfant a été transféré en tant qu\'adhérent avec succès.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        fetchMembers();
      } else {
        toast({
          title: 'Erreur',
          description: 'Une erreur est survenue lors du transfert de l\'enfant en adhérent.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Erreur',
        description: `Une erreur est survenue : ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);

  const handleRemoveSelectedFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleDeleteExistingFile = async (fileId) => {
    try {
      const response = await fetch(`https://app.falconmarketing.fr:3004/adherents/${adherent.id}/files/${fileId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error("Erreur lors de la suppression du fichier");
      }

      const updatedFiles = files.filter(file => file.id !== fileId);
      setFiles(updatedFiles);

      toast({
        title: 'Succès',
        description: 'Le fichier a été supprimé avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erreur',
        description: `Impossible de supprimer le fichier: ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const fetchFiles = async () => {
      if (adherent && adherent.id) {
        try {
          const response = await fetch(`https://app.falconmarketing.fr:3004/adherents/${adherent.id}/files`);
          if (!response.ok) {
            throw new Error('Erreur lors du chargement des fichiers');
          }
          const data = await response.json();
          setFiles(data.files);
        } catch (error) {
          console.error('Erreur :', error);
          toast({
            title: 'Erreur',
            description: 'Impossible de charger les fichiers',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };

    if (isOpen) {
      fetchFiles();
    }
  }, [isOpen, adherent]);

  const handleTransferConfirmation = (child) => {
    setChildToTransfer(child);
    setIsTransferModalOpen(true);
  };

  const handleTransferCancel = () => {
    setChildToTransfer(null);
    setIsTransferModalOpen(false);
  };

  const handleTransferConfirm = async () => {
    if (childToTransfer) {
      await handleCreateAdherentFromChild(childToTransfer.id);
      setIsTransferModalOpen(false);
      setChildToTransfer(null);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent bg={modalBg}>
          <ModalHeader bg={headerBg} color="white">
            Modifier les informations du membre
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch" as="form" autoComplete="off">
              <FormControl>
                <FormLabel color={labelColor}>Nom et Prénom:</FormLabel>
                <HStack>
                  <Input 
                    name="mrName" 
                    onChange={handleChange} 
                    value={member.mrName} 
                    placeholder="Nom et prénom" 
                    bg={inputBg} 
                    borderColor={borderColor} 
                    color={textColor} 
                    _placeholder={{ color: placeholderColor }}
                    autoComplete="off" 
                  />
                  <Button colorScheme="red" onClick={() => handleCreateDeathRecord(member.mrName)}>Décès</Button>
                </HStack>
              </FormControl>
              <FormControl>
                <FormLabel color={labelColor}>Nom et Prénom:</FormLabel>
                <HStack>
                  <Input 
                    name="mrsName" 
                    onChange={handleChange} 
                    value={member.mrsName} 
                    placeholder="Nom et prénom" 
                    bg={inputBg} 
                    borderColor={borderColor} 
                    color={textColor} 
                    _placeholder={{ color: placeholderColor }}
                    autoComplete="off" 
                  />
                  <Button colorScheme="red" onClick={() => handleCreateDeathRecord(member.mrsName)}>Décès</Button>
                </HStack>
              </FormControl>

              <FormControl>
                <FormLabel color={labelColor}>Numéro de sécurité sociale</FormLabel>
                <Input 
                  name="socialSecurityNumber" 
                  onChange={handleChange} 
                  value={member.socialSecurityNumber} 
                  placeholder="Numéro de sécurité sociale" 
                  bg={inputBg} 
                  borderColor={borderColor} 
                  color={textColor}
                  _placeholder={{ color: placeholderColor }}
                  autoComplete="off" 
                />
              </FormControl>

              <FormControl>
                <FormLabel color={labelColor}>Numéro de sécurité sociale (conjoint)</FormLabel>
                <Input 
                  name="socialSecurityNumberLine2" 
                  onChange={handleChange} 
                  value={member.socialSecurityNumberLine2} 
                  placeholder="Numéro de sécurité sociale du conjoint" 
                  bg={inputBg} 
                  borderColor={borderColor} 
                  color={textColor}
                  _placeholder={{ color: placeholderColor }}
                  autoComplete="off" 
                />
              </FormControl>

              <FormControl>
                <FormLabel color={labelColor}>Date de naissance</FormLabel>
                <Input 
                  type="date" 
                  name="birthDate" 
                  onChange={handleChange} 
                  value={member.birthDate} 
                  bg={inputBg} 
                  borderColor={borderColor} 
                  color={textColor}
                  _placeholder={{ color: placeholderColor }}
                  autoComplete="off" 
                />
              </FormControl>

              <FormControl>
                <FormLabel color={labelColor}>Date de naissance (conjoint)</FormLabel>
                <Input 
                  type="date" 
                  name="birthDateLine2" 
                  onChange={handleChange} 
                  value={member.birthDateLine2} 
                  bg={inputBg} 
                  borderColor={borderColor} 
                  color={textColor}
                  _placeholder={{ color: placeholderColor }}
                  autoComplete="off" 
                />
              </FormControl>

              <FormControl>
                <FormLabel color={labelColor}>Adresse</FormLabel>
                <Input 
                  name="address" 
                  onChange={handleChange} 
                  value={member.address} 
                  placeholder="Adresse" 
                  bg={inputBg} 
                  borderColor={borderColor} 
                  color={textColor}
                  _placeholder={{ color: placeholderColor }}
                  autoComplete="off" 
                />
              </FormControl>

              <FormControl>
                <FormLabel color={labelColor}>Téléphone</FormLabel>
                <Input 
                  name="phone" 
                  onChange={handleChange} 
                  value={member.phone} 
                  placeholder="Téléphone" 
                  bg={inputBg} 
                  borderColor={borderColor} 
                  color={textColor}
                  _placeholder={{ color: placeholderColor }}
                  autoComplete="off" 
                />
              </FormControl>

              <FormControl>
                <FormLabel color={labelColor}>Email</FormLabel>
                <Input 
                  name="email" 
                  onChange={handleChange} 
                  value={member.email} 
                  placeholder="Email" 
                  bg={inputBg} 
                  borderColor={borderColor} 
                  color={textColor}
                  _placeholder={{ color: placeholderColor }}
                  autoComplete="off" 
                />
              </FormControl>

              <FormControl>
                <FormLabel color={labelColor}>Nationalité</FormLabel>
                <Input 
                  name="nationality" 
                  onChange={handleChange} 
                  value={member.nationality} 
                  placeholder="Nationalité" 
                  bg={inputBg} 
                  borderColor={borderColor} 
                  color={textColor}
                  _placeholder={{ color: placeholderColor }}
                  autoComplete="off" 
                />
              </FormControl>

              <FormControl>
                <FormLabel color={labelColor}>Lieu d'inhumation</FormLabel>
                <Input 
                  name="burialSite" 
                  onChange={handleChange} 
                  value={member.burialSite} 
                  placeholder="Lieu d'inhumation" 
                  bg={inputBg} 
                  borderColor={borderColor} 
                  color={textColor}
                  _placeholder={{ color: placeholderColor }}
                  autoComplete="off" 
                />
              </FormControl>

              <FormControl>
                <FormLabel color={labelColor}>Situation familiale</FormLabel>
                <Select 
                  name="maritalStatus" 
                  onChange={handleChange} 
                  value={member.maritalStatus} 
                  placeholder="Sélectionnez la situation familiale" 
                  bg={inputBg} 
                  borderColor={borderColor} 
                  color={textColor}
                  _placeholder={{ color: placeholderColor }}
                  autoComplete="off"
                >
                  <option value="married">Marié</option>
                  <option value="single">Célibataire</option>
                  <option value="divorcedWithChildren">Divorcé avec enfants</option>
                  <option value="divorcedWithoutChildren">Divorcé sans enfants</option>
                  <option value="widowed">Veuf/Veuve</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel color={labelColor}>Statut de cotisation</FormLabel>
                <Select
                  name="contributionStatus"
                  onChange={handleChange}
                  value={member.contributionStatus}
                  placeholder="Sélectionnez le statut de cotisation"
                  bg={inputBg} 
                  borderColor={borderColor} 
                  color={textColor}
                  _placeholder={{ color: placeholderColor }}
                  autoComplete="off"
                >
                  <option value="À jour">À jour</option>
                  <option value="En retard">En retard</option>
                  <option value="Radié">Radié</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel color={labelColor}>Nom du délégué</FormLabel>
                <Select 
                  name="delegateName" 
                  onChange={handleChange} 
                  value={member.delegateName} 
                  placeholder="Sélectionnez un délégué" 
                  bg={inputBg} 
                  borderColor={borderColor} 
                  color={textColor}
                  _placeholder={{ color: placeholderColor }}
                  autoComplete="off"
                >
                  {delegates.map((delegate, index) => (
                    <option key={index} value={delegate}>
                      {delegate}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <Box>
                <FormLabel color={labelColor}>Enfants</FormLabel>
                {children.map((child, index) => (
                  <HStack key={index} mb={2}>
                    <Input 
                      name={`children.name.${index}`} 
                      placeholder="Nom de l'enfant" 
                      value={child.name} 
                      onChange={(e) => handleChange(e, index)} 
                      bg={inputBg} 
                      borderColor={borderColor} 
                      color={textColor}
                      _placeholder={{ color: placeholderColor }}
                      autoComplete="off" 
                    />
                    <Input 
                      type="date" 
                      name={`children.birthDate.${index}`} 
                      placeholder="Date de naissance" 
                      value={child.birthDate} 
                      onChange={(e) => handleChange(e, index)} 
                      bg={inputBg} 
                      borderColor={borderColor} 
                      color={textColor}
                      _placeholder={{ color: placeholderColor }}
                      autoComplete="off" 
                    />
                    <IconButton
                      aria-label="Créer un adhérent"
                      icon={<MdPersonAdd />}
                      onClick={() => handleTransferConfirmation(child)}
                      bg={inputBg}
                      color={textColor}
                      _hover={{ bg: hoverBg }}
                    />
                    <IconButton 
                      aria-label="Supprimer l'enfant" 
                      icon={<MdDelete />} 
                      onClick={() => handleRemoveChild(index)} 
                      bg={inputBg}
                      color={textColor}
                      _hover={{ bg: hoverBg }}
                      autoComplete="off" 
                    />
                  </HStack>
                ))}
                <Button leftIcon={<MdAdd />} colorScheme="teal" onClick={handleAddChild}>
                  Ajouter un enfant
                </Button>
              </Box>

              <Box mt={4}>
                <FormLabel color={labelColor}>Pièces justificatives</FormLabel>
                <Flex alignItems="center" mb={2}>
                  <IconButton
                    aria-label="Ajouter des fichiers"
                    icon={<MdUpload />}
                    onClick={() => fileInputRef.current.click()}
                    bg={inputBg} 
                    color={textColor}
                    _hover={{ bg: hoverBg }}
                  />
                  <input
                    type="file"
                    multiple
                    accept="application/pdf,image/jpeg,image/png"
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    autoComplete="off"
                  />
                </Flex>
                {selectedFiles.length > 0 && (
                  <Flex flexWrap="wrap">
                    {selectedFiles.map((file, index) => (
                      <Box key={index} mr={2} mb={2}>
                        <Text color={textColor}>{file.name}</Text>
                        <IconButton
                          aria-label="Supprimer le fichier"
                          icon={<MdDelete />}
                          colorScheme="red"
                          size="sm"
                          onClick={() => handleRemoveSelectedFile(index)}
                          bg={inputBg} 
                          color={textColor}
                          _hover={{ bg: hoverBg }}
                          autoComplete="off" 
                        />
                      </Box>
                    ))}
                  </Flex>
                )}
                {files.length > 0 && (
                  <VStack spacing={2} align="start" mt={4}>
                    {files.map((file) => (
                      <HStack key={file.id} spacing={2}>
                        <Icon as={MdDownload} boxSize={5} />
                        <Text color={textColor}>{file.filePath.split('/').pop()}</Text>
                        <IconButton
                          aria-label="Télécharger le fichier"
                          icon={<MdDownload />}
                          colorScheme="blue"
                          size="sm"
                          as="a"
                          href={`https://app.falconmarketing.fr:3004/${file.filePath}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          bg={inputBg}
                          color={textColor}
                          _hover={{ bg: hoverBg }}
                          autoComplete="off"
                        />
                        <IconButton
                          aria-label="Supprimer le fichier"
                          icon={<MdDelete />}
                          colorScheme="red"
                          size="sm"
                          onClick={() => handleDeleteExistingFile(file.id)}
                          bg={inputBg} 
                          color={textColor}
                          _hover={{ bg: hoverBg }}
                          autoComplete="off" 
                        />
                      </HStack>
                    ))}
                  </VStack>
                )}
              </Box>
              
            </VStack>

          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={() => setIsDeleteAlertOpen(true)}>
              Supprimer
            </Button>
            <Button colorScheme="blue" mr={3} onClick={handleSave}>
              Sauvegarder
            </Button>
            <Button onClick={onClose}>Annuler</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isDeleteAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Supprimer l'adhérent
            </AlertDialogHeader>

            <AlertDialogBody>
              Êtes-vous sûr ? Vous ne pouvez pas annuler cette action par la suite.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleteAlertOpen(false)}>
                Annuler
              </Button>
              <Button colorScheme="red" onClick={handleDeleteAdherent} ml={3}>
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isTransferModalOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleTransferCancel}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Transformer en adhérent
            </AlertDialogHeader>

            <AlertDialogBody>
              Êtes-vous sûr de vouloir transformer {childToTransfer?.name} en adhérent ? 
              Cette action créera un nouveau membre à partir des informations de l'enfant.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={handleTransferCancel}>
                Annuler
              </Button>
              <Button colorScheme="blue" onClick={handleTransferConfirm} ml={3}>
                Confirmer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default EditMemberModal;